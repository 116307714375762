import React from 'react';
import {Link} from 'react-router-dom';

import {Card, CardActionArea, CardActions, CardContent, Grid, styled, Typography} from '@mui/material';

import {IntegrationStatusType} from '../types';
import IntegrationCardStatus from './IntegrationCardStatus';

const ApplicationChip = styled('div')({
  background: '#164C7414',
  fontSize: '12px',
  borderRadius: '6px',
  padding: '4px 8px',
  display: 'inline',
});

interface IntegrationCardLayoutProps {
  icon: React.ReactNode;
  status: IntegrationStatusType | null;
  href: string;
  title: string;
  description: string;
  apps: string[];
}

export default function IntegrationCardLayout(props: IntegrationCardLayoutProps) {
  const {icon, status, href, title, description, apps} = props;

  return (
    <Card sx={{minWidth: 268, maxWidth: 268, m: 2, textAlign: 'left'}}>
      <CardActionArea
        component={Link}
        to={href}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          justifyContent: 'stretch',
        }}>
        <CardContent sx={{flex: 1}}>
          {icon}
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Grid container spacing={0.5} sx={{paddingTop: 0.5, paddingBottom: 0.5}}>
            {apps.map((name) => (
              <Grid item key={name}>
                <ApplicationChip>{name}</ApplicationChip>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body2" mt={1}>
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{pt: 1, pl: 2, pb: 1, pr: 2}}>
          <IntegrationCardStatus status={status} />
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
