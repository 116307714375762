export const ErrorCode = {
  // MS Presence
  SUBSCRIPTIONS_ALREADY_PRESENT: 'subscriptions_already_present',
  WRONG_STATUS: 'wrong_status',

  NO_REFRESH_TOKEN: 'no_refresh_token',
  NO_PBX_USERS: 'no_pbx_users',
  NO_MS_USERS: 'no_ms_users',
  NO_DYNAMO_USERS: 'no_dynamo_users',
  NO_USERS_ON_MS_AND_PBX: 'no_users_on_ms_and_pbx',
  MAX_USERS_LIMIT_REACHED: 'max_users_limit_reached',

  UNEXPECTED_ERROR: 'unexpected_error',

  USER_SUBSCRIPTION_ERROR: 'user_subscription_error',
  PRESENCE_SUBSCRIPTION_ERROR: 'presence_subscription_error',

  NO_ACTIVE_AUTHORIZATIONS: 'no_active_authorizations',

  MISSING_EXTERNAL_ID: 'missing_external_id',
  MISSING_TENANT_ID: 'missing_tenant_id',
};
