import React, {useCallback, useState} from 'react';

import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import {runNangoAuthFlow} from '../../../base/helpers/runNangoAuthFlow';
import SalesforceBackendIcon from '../../../base/icons/SalesforceBackendIcon';
import {IntegrationService} from '../../../base/types';
import {SALESFORCE_BACKEND_DESCRIPTION, SALESFORCE_BACKEND_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation, {createIntegration} from '../../hooks/useIntegrationInstallationMutation';
import {SalesforceBackendEnvironment} from '../types';

export default function SalesforceBackendIntegrationScreen() {
  const [providerConfigKey, setProviderConfigKey] = useState<string>(SalesforceBackendEnvironment.live);
  const service = IntegrationService.SALESFORCE_BACKEND;

  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, service);

  const [winOpened, setWinOpened] = useState(false);
  const [installError, setInstallError] = useState('');

  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({});

  const changeEnvironment = useCallback((e: any) => setProviderConfigKey(e.target.value), []);
  const onInstallClick = useCallback(() => {
    if (winOpened) {
      return;
    }

    setWinOpened(true);

    createIntegration({service, data: {providerConfigKey}})
      .then(({result: params}) => runNangoAuthFlow(params))
      .then((result: any) => {
        mutate({service, data: result});
      })
      .catch((err) => {
        if (err.type === 'windowClosed') {
          console.log(err.message);
        } else {
          setInstallError(err.message);
        }
      })
      .finally(() => {
        setWinOpened(false);
      });
  }, [winOpened, mutate, service, providerConfigKey]);

  if (isLoading || isInstallationLoading) {
    return <LoadingBox />;
  }

  if (isError || installError) {
    return <ApiErrorBox error={error || installError} />;
  }

  return (
    <IntegrationScreen
      title={SALESFORCE_BACKEND_TITLE}
      description={SALESFORCE_BACKEND_DESCRIPTION}
      icon={<SalesforceBackendIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={onInstallClick}
      isInstallLoading={winOpened}
      options={
        <FormControl>
          <FormLabel id="radio-buttons-group-label">Environment</FormLabel>
          <RadioGroup
            onChange={changeEnvironment}
            aria-labelledby="radio-buttons-group-label"
            value={providerConfigKey}
            name="radio-buttons-group">
            <FormControlLabel value={SalesforceBackendEnvironment.live} control={<Radio />} label="Live" />
            <FormControlLabel value={SalesforceBackendEnvironment.sandbox} control={<Radio />} label="Sandbox" />
          </RadioGroup>
        </FormControl>
      }
    />
  );
}
