import * as React from 'react';
import {ChangeEvent, MutableRefObject, useCallback, useState} from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IntegrationRemoveDialogApi {
  open: CallableFunction;
}

interface IntegrationRemoveDialogProps {
  apiRef: MutableRefObject<IntegrationRemoveDialogApi>;
  title: string;
  onDeleteClick: ReturnType<any>;
}

export default function IntegrationDeleteDialog(props: IntegrationRemoveDialogProps) {
  const {apiRef, title, onDeleteClick} = props;

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onDialogClose = () => {
    setOpen(false);
    setDeleteText('');
  };

  const onDeleteButtonClick = () => {
    onDialogClose();
    onDeleteClick();
  };

  apiRef.current = {
    open: () => setOpen(true),
  };

  const [deleteText, setDeleteText] = useState('');
  const onDeleteFieldChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDeleteText(event.target.value);
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onDialogClose}
      aria-labelledby="integration-remove-dialog-title">
      <DialogTitle id="integration-remove-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>Are you sure you want to delete this integration ?</span>
          &nbsp;
          <b>You can't undo this action.</b>
        </DialogContentText>
        <DialogContentText sx={{pt: 1}}>
          To confirm deletion, enter the phase <b>delete</b>.
        </DialogContentText>
        <Box sx={{pt: 2}}>
          <TextField
            size="small"
            required
            fullWidth
            placeholder="delete"
            autoFocus
            onChange={onDeleteFieldChange}
            value={deleteText}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDialogClose}>
          Cancel
        </Button>
        <Button
          onClick={onDeleteButtonClick}
          color="error"
          startIcon={<DeleteOutlineIcon />}
          disabled={deleteText !== 'delete'}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
