import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useQueryClient} from 'react-query';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';

import {
  CreateBotInput,
  UpdateBotCallbackCommand,
  UpdateBotCommand,
  UploadPictureCommand,
} from '@wildix/xbees-users-client';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';
import ChatBotsIcon from '../../../base/icons/ChatBotsIcon';
import {CHATBOTS_TITLE} from '../../../texts';
import {BotsPath} from '../constants';
import useGetBotCallbackQuery from '../hooks/useGetBotCallbackQuery';
import useGetBotQuery from '../hooks/useGetBotQuery';
import {BotFormData} from '../types';
import BotForm from './BotForm';

interface BotsAddScreenParams extends Record<string, string> {
  botId: string;
}

export default function BotEditScreen() {
  const params = useParams<BotsAddScreenParams>();
  const navigate = useNavigate();
  const form = useForm<BotFormData>({});
  const {setError} = form;
  const [initialized, setInitialized] = useState(false);

  const queryClient = useQueryClient();
  const queryBotInfo = useGetBotQuery(params.botId);
  const queryBotCallback = useGetBotCallbackQuery(params.botId);

  useEffect(() => {
    if (queryBotInfo.data && queryBotCallback.data && !initialized) {
      form.reset({
        id: params.botId,
        name: queryBotInfo.data.bot.name,
        picture: queryBotInfo.data.bot.picture,
        searchable: queryBotInfo.data.bot.searchable,
        callback: queryBotCallback.data.callback,
      });
      setInitialized(true);
    }
  }, [queryBotInfo.data, queryBotCallback.data]);

  const onSubmit = useCallback(
    async (data: BotFormData) => {
      const {name, picture, searchable, callback} = data;
      const client = getXbsUsersClient();
      const content: CreateBotInput = {
        name,
        picture,
        searchable: !!searchable,
        callback,
      };

      try {
        if (picture && picture.startsWith('data')) {
          const response = await client.send(
            new UploadPictureCommand({
              picture,
            }),
          );

          content.picture = response.url;
        }

        await client.send(
          new UpdateBotCommand({
            botId: params.botId!,
            name: content.name,
            picture: content.picture,
            searchable: content.searchable,
          }),
        );

        await client.send(
          new UpdateBotCallbackCommand({
            botId: params.botId!,
            callback,
          }),
        );

        queryClient.resetQueries('chatbots');

        navigate(BotsPath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  if (queryBotInfo.isError || queryBotCallback.isError) {
    return <ApiErrorBox error={queryBotInfo.error || queryBotCallback.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={BotsPath.base}>
          {CHATBOTS_TITLE}
        </Typography>
        <Typography color="text.primary">
          {queryBotInfo.data ? queryBotInfo.data.bot.name : <CircularProgress size={12} />}
        </Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <ChatBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            {queryBotInfo.data ? queryBotInfo.data.bot.name : <CircularProgress size={12} />}
          </Typography>

          {queryBotInfo.isLoading || queryBotCallback.isLoading ? (
            <Box mt={5}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
          {initialized ? <BotForm form={form} onSubmit={onSubmit} /> : null}
        </Box>
      </Box>
    </Box>
  );
}
