import React from 'react';
import {Link} from 'react-router-dom';

import {Breadcrumbs, Grid, Typography} from '@mui/material';

import IframeIntegrationIcon from '../../../base/icons/IframeIntegrationIcon';
import {IFRAME_INTEGRATION_TITLE} from '../../../texts';
import IframeIntegrationWrapper from './IframeIntegrationWrapper';

function IframeIntegrationScreen() {
  return (
    <Grid sx={{p: 1, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary">{IFRAME_INTEGRATION_TITLE}</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} sx={{marginTop: 2}}>
        <Grid item xs={1.5}>
          <IframeIntegrationIcon width={96} height={96} />
        </Grid>
        <Grid item xs={10.5}>
          <Typography sx={{paddingTop: '25px'}} color="text.primary" variant="h5">
            {IFRAME_INTEGRATION_TITLE}
          </Typography>
        </Grid>
      </Grid>
      <Typography sx={{mt: 1}} color="text.primary" variant="caption" display="block">
        For setup instructions, check out{' '}
        <a target="_blank" rel="noreferrer" href="https://wildix.atlassian.net/wiki/x/AQCFDw">
          documentation
        </a>
      </Typography>
      <Grid>
        <IframeIntegrationWrapper />
      </Grid>
    </Grid>
  );
}

export default IframeIntegrationScreen;
