import * as React from 'react';
import {ChangeEvent, MutableRefObject, useCallback, useState} from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IntegrationRemoveDialogApi {
  open: CallableFunction;
}

interface DeleteDialogProps {
  apiRef: MutableRefObject<IntegrationRemoveDialogApi>;
  action: () => Promise<unknown>;
}

export default function DeleteDialog(props: DeleteDialogProps) {
  const {apiRef, action} = props;

  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState('');
  const [isLoading, setLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  }, []);

  const onDialogClose = (force?: boolean) => {
    if (!isLoading || force) {
      setOpen(false);
      setLoading(false);
      setText('');
    }
  };

  const onDeleteButtonClick = () => {
    setLoading(true);

    const promise = action();
    promise.then(() => onDialogClose(true));
    promise.catch(() => {
      setLoading(false);
    });
  };

  apiRef.current = {
    open: () => {
      setOpen(true);
    },
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => onDialogClose()}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you certain you want to delete this? <b>This action cannot be undone.</b>
        </DialogContentText>
        <DialogContentText sx={{pt: 1}}>
          To confirm deletion, enter the phase <b>delete</b>.
        </DialogContentText>
        <Box sx={{pt: 2}}>
          <TextField
            size="small"
            required
            fullWidth
            placeholder="delete"
            autoFocus
            disabled={isLoading}
            onChange={onTextChange}
            value={text}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} autoFocus onClick={() => onDialogClose()}>
          Cancel
        </Button>
        <LoadingButton
          onClick={onDeleteButtonClick}
          color="error"
          loading={isLoading}
          startIcon={<DeleteOutlineIcon />}
          disabled={text !== 'delete'}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
