export const WIM_ENV = process.env.REACT_APP_ENV || 'stage';
export const WIM_API =
  WIM_ENV === 'production'
    ? 'https://wim.wildix.com'
    : WIM_ENV === 'stable'
      ? 'https://wim-stable.wildix.com'
      : 'https://wim-stage.wildix.com';
export const MICROSOFT_AZURE_APP_ID = process.env.MICROSOFT_AZURE_APP_ID || '53b57eec-889f-42eb-9001-8d79f9dbf892'; // Azure application (client) ID for Microsoft 365 integration
export const MICROSOFT_PRESENCE_AZURE_APP_ID =
  process.env.MICROSOFT_PRESENCE_AZURE_APP_ID || '540ec93d-462c-430f-86cb-ddd28ed3f834'; // Azure application (client) ID for Microsoft Presence integration

export const ENV = WIM_ENV === 'production' ? 'prod' : WIM_ENV === 'stable' ? 'stable' : 'stage';
