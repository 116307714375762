import React, {useCallback} from 'react';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid} from '@mui/material';

import LoadingBox from '../../../base/components/LoadingBox';
import {Integration, IntegrationService} from '../../../base/types';
import {PREPARED_PUBLIC_INTEGRATION} from '../constants';
import {IframeIntegration, PositionDirection} from '../types';
import IframeIntegrationItemForm from './IframeIntegrationItemForm';

export interface IframeIntegrationsProps {
  integrations: IframeIntegration[];
  isInstallationLoading: boolean;
  warningMessage?: string;
  isCILoading: boolean;
  onDeleteClick: Function;
  isDeleteLoading: boolean;
  isChangePositionLoading: boolean;
  sendInstallIntegration: Function;
  sendChangePosition: Function;
}

function IframeIntegrationsInstalledList(props: IframeIntegrationsProps) {
  const {
    integrations,
    isCILoading,
    isChangePositionLoading,
    isInstallationLoading,
    sendChangePosition,
    sendInstallIntegration,
    isDeleteLoading,
    onDeleteClick,
    warningMessage,
  } = props;
  const sortIntegrations = ({data: {position: a}}: Integration, {data: {position: b}}: Integration) =>
    a > b ? 1 : a < b ? -1 : 0;
  const changeIframePosition = useCallback(
    (integration: IframeIntegration, command: PositionDirection): any => {
      if (isChangePositionLoading) {
        return;
      }

      const {
        integrationId: currentIntegrationId,
        data: {position: currentPosition},
      } = integration;

      if (!currentPosition) {
        return {};
      }

      const targetIntegration = integrations
        .filter(({data: {position}}: any) =>
          command === PositionDirection.UP ? currentPosition > Number(position) : currentPosition < Number(position),
        )
        .sort(sortIntegrations);

      if (!targetIntegration.length) {
        return {};
      }

      const {integrationId: targetIntegrationId} =
        command === PositionDirection.UP ? targetIntegration[targetIntegration.length - 1] : targetIntegration[0];

      sendChangePosition({
        service: IntegrationService.IFRAME_INTEGRATION,
        data: {
          currentIntegrationId,
          targetIntegrationId,
        },
      });
    },
    [sendChangePosition, integrations, isChangePositionLoading],
  );

  if (isCILoading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <LoadingBox />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{marginTop: 2, marginBottom: 2, fontSize: '18px'}}>Installed Integrations</Box>
      <Grid container sx={{maxWidth: 645, padding: '15px 0 25px 0', borderRadius: '5px'}}>
        <Box
          sx={{
            width: '100%',
            marginBottom: 4,
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 14,
            borderBottom: '1px solid #ccc',
          }}>
          <Box>Name</Box>
          <Box sx={{marginRight: '3%'}}>Position</Box>
        </Box>
        {(integrations.length &&
          integrations.sort(sortIntegrations).map((integration: IframeIntegration, index: number) => {
            const {
              integrationId,
              data: {title, templateId: targetTemplateId, isPreparedIntegration},
            } = integration;
            let Icon;

            if (isPreparedIntegration) {
              const foundPreparedIntegration = PREPARED_PUBLIC_INTEGRATION.find((preparedIntegration) => {
                const {
                  config: {templateId},
                } = preparedIntegration;

                return targetTemplateId === templateId;
              });

              Icon = foundPreparedIntegration?.properties?.Icon;
            }

            return (
              <Grid
                key={integrationId}
                sx={{marginTop: 1, marginBottom: 1, flexWrap: 'nowrap'}}
                alignItems="baseline"
                justifyContent="space-between"
                container
                direction="row">
                <Grid item xs={10}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="integration-content"
                      id="integration-header">
                      <Grid alignItems="center" justifyContent="space-between" container direction="row">
                        <Box sx={{fontSize: 16, paddingBottom: 0.6}}>
                          <ArrowRightIcon
                            sx={{position: 'relative', width: 35, top: 6, paddingRight: '10px', color: '#666'}}
                          />
                          {Icon ? (
                            <Box style={{display: 'inline-flex', position: 'relative', top: '4px', left: '-10px'}}>
                              <Icon width="20px" fontSize="small" />
                            </Box>
                          ) : (
                            ''
                          )}
                          {title}
                        </Box>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <IframeIntegrationItemForm
                        isPreparedIntegration={isPreparedIntegration}
                        sendInstallIntegration={sendInstallIntegration}
                        isInstallationLoading={isInstallationLoading}
                        isDeleteLoading={isDeleteLoading}
                        onDeleteClick={onDeleteClick}
                        integration={integration}
                        warningMessage={warningMessage}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{minWidth: 60, opacity: isChangePositionLoading ? 0.2 : 1, paddingLeft: 2}}
                  alignItems="baseline"
                  container
                  direction="row"
                  justifyContent="space-around">
                  {index !== 0 ? (
                    <Box
                      onClick={() => changeIframePosition(integration, PositionDirection.UP)}
                      sx={{position: 'relative', top: 3, cursor: 'pointer'}}>
                      <ArrowCircleUpIcon fontSize="small" viewBox="0 0 24 24" color="action" />
                    </Box>
                  ) : null}
                  {integrations.length - 1 !== index ? (
                    <Box
                      onClick={() => changeIframePosition(integration, PositionDirection.DOWN)}
                      sx={{position: 'relative', top: 3, cursor: 'pointer'}}>
                      <ArrowCircleDownIcon fontSize="small" viewBox="0 0 24 24" color="action" />
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            );
          })) || <Box sx={{fontSize: 14, textDecoration: ''}}>List is empty</Box>}
      </Grid>
    </>
  );
}

export default IframeIntegrationsInstalledList;
