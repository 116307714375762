import React, {useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, IconButton, Stack} from '@mui/material';

import DeleteDialog from '../../../../base/components/dialogs/DeleteDialog';
import PencilIcon20 from '../../../../base/icons/PencilIcon20';
import RemoveIcon20 from '../../../../base/icons/RemoveIcon20';
import {Integration} from '../../../../base/types';
import useConsoleIntegrationDeleteMutation from '../../../hooks/useConsoleIntegrationDeleteMutation';
import {WebhooksIntegrationPath} from '../../constants';
import {TransmissionType} from '../../types';
import ListItemTable from './ListItemTable';

interface WebhookListItem {
  // TODO
  item: Integration;
}

export default function WebhookListItem(props: WebhookListItem) {
  const {item} = props;
  const events = item.data.events as {value: string}[];
  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(`${WebhooksIntegrationPath.base}/${item.integrationId}`);
  };

  const deleteMutation = useConsoleIntegrationDeleteMutation({
    onSuccess: () => {
      navigate(`${WebhooksIntegrationPath.base}?mutate=true`);
    },
  });

  const removeDialogRef = useRef<any>();

  const onDeleteClick = useCallback(() => {
    removeDialogRef.current.open();
  }, []);

  const onDeleteConfirmClick = useCallback(
    () => deleteMutation.mutateAsync(item.integrationId),
    [item, deleteMutation],
  );

  return (
    <Box mt={3}>
      <Stack display="flex" flexDirection="row" alignItems="center">
        <Box flex="1">Integration #{item.integrationId}</Box>
        <Stack display="flex" flexDirection="row">
          <IconButton onClick={onEditClick} sx={{width: '32px', height: '32px', padding: '6px'}}>
            <PencilIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
          <IconButton onClick={onDeleteClick} sx={{width: '32px', height: '32px', padding: '6px'}}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Stack>
      </Stack>
      <Box mt={1}>
        <ListItemTable
          rows={[
            {label: 'Type', value: item.data.type === TransmissionType.WEBHOOK ? 'Webhook' : 'AWS SQS'},
            {label: 'Url', value: item.data.url},
            {label: 'Events', value: events.map(({value}) => value).join(', ')},
          ]}
        />
      </Box>
      <DeleteDialog apiRef={removeDialogRef} action={onDeleteConfirmClick} />
    </Box>
  );
}
