import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, Typography} from '@mui/material';

import {CreateBotCommand, CreateBotInput, UploadPictureCommand} from '@wildix/xbees-users-client';

import getRandomString from '../../../base/helpers/getRandomString';
import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';
import ChatBotsIcon from '../../../base/icons/ChatBotsIcon';
import {CHATBOTS_TITLE} from '../../../texts';
import {BotsPath} from '../constants';
import {BotFormData} from '../types';
import BotForm from './BotForm';

export default function BotAddScreen() {
  const navigate = useNavigate();
  const form = useForm<BotFormData>({
    defaultValues: {
      name: '',
      picture: '',
      callback: {
        endpoint: {
          webhook: {
            url: '',
            secret: getRandomString(),
          },
        },
      },
    },
  });
  const {setError} = form;

  const onSubmit = useCallback(
    async (data: BotFormData) => {
      const {name, picture, searchable, callback} = data;
      const client = getXbsUsersClient();
      const content: CreateBotInput = {
        name,
        picture,
        searchable: !!searchable,
        callback,
      };

      try {
        if (picture && picture.startsWith('data')) {
          const response = await client.send(
            new UploadPictureCommand({
              picture,
            }),
          );

          content.picture = response.url;
        }

        const response = await client.send(new CreateBotCommand(content));

        console.log('response', response);

        navigate(BotsPath.base);
      } catch (error) {
        // @ts-ignore
        setError('name', {message: error.toString()});
      }
    },
    [navigate],
  );

  // TODO: Add validation for events, should not be empty.

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={BotsPath.base}>
          {CHATBOTS_TITLE}
        </Typography>
        <Typography color="text.primary">Add</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <ChatBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            New Chat Bot
          </Typography>

          <BotForm form={form} onSubmit={onSubmit} />
        </Box>
      </Box>
    </Box>
  );
}
