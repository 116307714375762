export default function GongIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="layer" x="0px" y="0px" viewBox="0 0 217 217" {...props}>
      <path
        fill="#8039E9"
        d="m 200.81118,92.926708 h -57 c -3.3,0 -5.7,3.3 -4.6,6.4 l 13.7,35.500002 c 0.6,1.6 -0.6,3.3 -2.3,3.2 l -17.5,-1.1 c -0.8,-0.1 -1.6,0.3 -2,1 l -13.6,19.3 c -0.7,1.1 -2.2,1.5 -3.3,0.7 l -20.2,-13.7 c -0.8,-0.5 -1.9,-0.5 -2.6,0 l -28,18.9 c -1.8,1.2 -4.2,-0.5 -3.6,-2.6 l 7.8,-27.8 c 0.3,-1.2 -0.2,-2.3 -1.4,-2.8 l -14.8,-6 c -1.5,-0.6 -1.9,-2.4 -0.9,-3.6 l 13,-16.2 c 0.6,-0.8 0.7,-1.9 0.1,-2.8 l -11,-15.900002 c -1,-1.5 -0.1,-3.5 1.8,-3.6 l 17.1,-1.3 c 1.2,-0.1 2.2,-1.2 2.2,-2.5 l -1.4,-23.9 c -0.1,-1.7 1.6,-2.9 3.3,-2.3 l 21.2,8.8 c 0.9,0.4 2,0.2 2.6,-0.6 l 14.6,-16.3 c 1.2,-1.3 3.3,-0.9 4,0.6 l 8.8,22.6 c 1.2,2.8 4.7,3.8 7.1,1.9 l 34.3,-25.5 c 3.9,-2.9 1.4,-9.1 -3.4,-8.4 l -22.6,3 c -1.1,0.2 -2.1,-0.5 -2.5,-1.5 l -11.9,-30.4999999 c -1.3,-3.2 -5.4,-4 -7.8,-1.4 l -25.8,27.9999999 c -0.7,0.7 -1.7,0.9 -2.6,0.5 l -34,-14.3 c -3,-1.2 -6.4,0.9 -6.5,4.2 l -1.2,35.1 c -0.1,1.2 -1,2.2 -2.2,2.2 l -31,2 c -3.6,0.2 -5.6,4.3 -3.6,7.3 l 20.6,30.4 c 0.6,0.900002 0.5,2.000002 -0.2,2.900002 l -20.9,24 c -2.1,2.4 -1.2,6.1 1.7,7.4 l 24,10.4 c 1.1,0.5 1.6,1.6 1.3,2.7 l -15.4,60 c -1.1,4.3 3.7,7.5 7.3,5 l 57.7,-41.4 c 0.8,-0.5 1.9,-0.6 2.7,0 l 26.3,18.5 c 2.2,1.6 5.1,0.9 6.6,-1.2 l 16.4,-25.2 c 0.5,-0.8 1.4,-1.2 2.2,-1 l 39.3,4.7 c 3.2,0.5 6.4,-2.2 5.2,-5.1 l -16.4,-42.3 c -0.5,-1.2 0,-2.4 1.4,-3.1 l 29.9,-14.1 c 4.6,-2.400002 3,-9.200002 -2,-9.200002 z"
        id="path1"
      />
    </svg>
  );
}
