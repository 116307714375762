import cryptoRandomString from 'crypto-random-string';

export enum RandomTypes {
  ALPHANUMERIC = 'alphanumeric',
  HEX = 'hex',
  BASE64 = 'base64',
  URL_SAFE = 'url-safe',
  NUMERIC = 'numeric',
  DISTINGUISHABLE = 'distinguishable',
  ASCII_PRINTABLE = 'ascii-printable',
}

export default function getRandomString(length: number = 30, type: RandomTypes = RandomTypes.ALPHANUMERIC) {
  return cryptoRandomString({length, type});
}
