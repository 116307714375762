import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {Checkbox, FormControlLabel} from '@mui/material';

import {BotFormData} from '../../types';

interface BotFieldSearchableProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotSearchableField(props: BotFieldSearchableProps) {
  const {form} = props;
  const {control, formState} = form;
  const {isSubmitting} = formState;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <FormControlLabel
          control={
            <Checkbox
              inputRef={ref}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              disabled={isSubmitting}
              checked={!!value}
              value={!!value}
            />
          }
          label="Allow users to find the bot using search."
        />
      )}
      name="searchable"
    />
  );
}
