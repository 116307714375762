import {useCallback, useEffect, useRef, useState} from 'react';

import {MICROSOFT_OAUTH_RESPONSE} from '../constants';
import openPopupWindow from '../helpers/openPopupWindow';

interface MicrosoftAuthorizationProps {
  authorizeUrl: string;
  clientId: string;
  redirectUri: string;
  onSuccess: (tenant: string) => void;
  onError: (error: string, description: string) => void;
}

export default function useMicrosoftAdminAuthorization({
  authorizeUrl,
  clientId,
  redirectUri,
  onSuccess,
  onError,
}: MicrosoftAuthorizationProps) {
  const popupUrl = `${authorizeUrl}?client_id=${clientId}&redirect_uri=${redirectUri}`;
  const popupRef = useRef<Window | null>();
  const intervalRef = useRef<any>();
  const [loading, setLoading] = useState(false);

  const onMessage = useCallback(
    (event: MessageEvent<any>) => {
      const {data} = event;

      if (data.event !== MICROSOFT_OAUTH_RESPONSE) {
        return;
      }

      if (data.tenant) {
        onSuccess(data.tenant);
      } else {
        console.warn('Microsoft authorization failed', data);
        onError(data.error, data.errorDescription);
      }

      popupRef.current?.close();
      setLoading(false);
      clearInterval(intervalRef.current);
      window.removeEventListener('message', onMessage);
    },
    [onError, onSuccess],
  );

  const authorize = useCallback(() => {
    setLoading(true);
    popupRef.current = openPopupWindow(popupUrl, 'Office 365 Admin Consent | Sing In', 650, 850);

    window.addEventListener('message', onMessage);

    intervalRef.current = setInterval(() => {
      const popupWindow = popupRef.current?.window;
      const popupClosed = !popupWindow || popupWindow?.closed;

      if (popupClosed) {
        setLoading(false);
        clearInterval(intervalRef.current);
        window.removeEventListener('message', onMessage);
      }
    }, 500);
  }, [popupUrl, onMessage]);

  useEffect(
    () => () => {
      popupRef.current?.close();
      clearInterval(intervalRef.current);
      window.removeEventListener('message', onMessage);
    },
    [onMessage],
  );

  return {isLoading: loading, authorize};
}
