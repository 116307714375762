import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import SalesforceCommunityIcon from '../../../base/icons/SalesforceCommunityIcon';
import {IntegrationService} from '../../../base/types';
import {SALESFORCE_COMMUNITY_SHORT_DESCRIPTION, SALESFORCE_COMMUNITY_TITLE} from '../../../texts';
import {SalesforceCommunityIntegrationPath} from '../types';

export default function SalesforceCommunityIntegrationCard(props: any) {
  const {integrations} = props;

  return (
    <IntegrationCard
      icon={<SalesforceCommunityIcon width={50} height={50} />}
      title={SALESFORCE_COMMUNITY_TITLE}
      description={SALESFORCE_COMMUNITY_SHORT_DESCRIPTION}
      href={SalesforceCommunityIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.SALESFORCE_COMMUNITY}
      apps={['x-bees']}
    />
  );
}
