import React from 'react';

import IntegrationCardLayout from '../../../base/components/IntegrationCardLayout';
import ChatBotsIcon from '../../../base/icons/ChatBotsIcon';
import {IntegrationStatusType} from '../../../base/types';
import {CHATBOTS_SHORT_DESCRIPTION, CHATBOTS_TITLE} from '../../../texts';
import {BotsPath} from '../constants';
import useListBotsQuery from '../hooks/useListBotsQuery';

export default function BotsCard() {
  const query = useListBotsQuery();

  return (
    <IntegrationCardLayout
      icon={<ChatBotsIcon width={50} height={50} />}
      title={CHATBOTS_TITLE}
      description={CHATBOTS_SHORT_DESCRIPTION}
      href={BotsPath.base}
      status={query.isSuccess && query.data.bots.length > 0 ? IntegrationStatusType.ENABLED : null}
      apps={['x-bees']}
    />
  );
}
