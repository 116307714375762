import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {Grid} from '@mui/material';

import {statusStyles} from './styles';

export default function EnabledStatus() {
  return (
    <Grid
      sx={{
        ...statusStyles,
        color: '#2E7B39',
        backgroundColor: 'rgba(36, 194, 60, 0.12)',
      }}>
      <CheckIcon sx={{fontSize: '1.2rem', marginRight: '5px'}} />
      <Grid item>Installed</Grid>
    </Grid>
  );
}
