import React, {useCallback, useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import ApiErrorBox from '../../../../base/components/error/ApiErrorBox';
import IntegrationInstallationScreen from '../../../../base/components/IntegrationInstallationScreen';
import LoadingBox from '../../../../base/components/LoadingBox';
import findIntegrationByService from '../../../../base/helpers/findIntegrationByService';
import GoogleCalendarIcon from '../../../../base/icons/GoogleCalendarIcon';
import {IntegrationService} from '../../../../base/types';
import {GOOGLE_CALENDAR_TITLE} from '../../../../texts';
import useConsoleIntegrations from '../../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation from '../../../hooks/useIntegrationInstallationMutation';
import {GoogleIntegrationPath} from '../../types';
import InstallationDescription from './InstallationDescription';
import InstallationForm from './InstallationForm';

export default function GoogleCalendarInstallationScreen(props: any) {
  const form = useForm({
    defaultValues: {
      administrator: '',
      domain: '',
    },
  });
  const navigate = useNavigate();
  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, IntegrationService.GOOGLE_CALENDAR);

  const mutatePromiseRef = useRef<ReturnType<any> | null>(null);
  const {mutate} = useIntegrationInstallationMutation({
    onSuccess: (response) => {
      const {success, message} = response;

      if (!success) {
        form.setError('administrator', {message}, {shouldFocus: true});
      } else {
        navigate(GoogleIntegrationPath.base);
      }
    },
    onSettled: () => {
      if (mutatePromiseRef.current) {
        mutatePromiseRef.current();
      }
    },
  });
  const onSubmit = useCallback(
    (data: any) => {
      const p = new Promise((resolve) => (mutatePromiseRef.current = resolve));

      mutate({service: IntegrationService.GOOGLE_CALENDAR, data});

      return p;
    },
    [mutate],
  );

  useEffect(() => {
    if (integration) {
      navigate('/');
    }
  }, [integration, navigate]);

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  return (
    <IntegrationInstallationScreen
      title={GOOGLE_CALENDAR_TITLE}
      icon={<GoogleCalendarIcon width={96} height={96} />}
      link={GoogleIntegrationPath.base}>
      <InstallationDescription />
      <InstallationForm form={form} onSubmit={onSubmit} />
    </IntegrationInstallationScreen>
  );
}
