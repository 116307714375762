import React from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {BotEndpoint} from '@wildix/xbees-users-client';

import {BotFormData} from '../../types';

interface BotSqsUrlFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotSqsUrlField(props: BotSqsUrlFieldProps) {
  const {form} = props;
  const {control, formState, trigger, getValues} = form;
  const {errors, isSubmitting} = formState;
  const error = (errors?.callback?.endpoint as FieldErrors<BotEndpoint.SqsMember>)?.sqs?.url;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          id="outlined-required"
          label="Target"
          placeholder="https://sqs.amazonaws.com/11111/wildix-events-queue"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="callback.endpoint.sqs.url"
      rules={{required: true, pattern: /^https:\/\/.+$/i}}
    />
  );
}
