import React, {MutableRefObject, useState} from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ErrorDialogApi {
  show: CallableFunction;
}

interface ErrorDialogProps {
  apiRef: MutableRefObject<ErrorDialogApi | undefined>;
}

export default function ErrorDialog({apiRef}: ErrorDialogProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  apiRef.current = {
    show: (error: string) => {
      setOpen(true);
      setError(error);
    },
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description">
      <DialogTitle id="error-dialog-title">Error</DialogTitle>
      <DialogContent sx={{minWidth: 300}}>
        <DialogContentText id="error-dialog-description" sx={{whiteSpace: 'pre-wrap'}}>
          {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
