import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Box, styled} from '@mui/material';
import Button from '@mui/material/Button';

import {useSnackbar} from 'material-ui-snackbar-provider';

import {BotFormData} from '../../types';

interface BotFieldPictureProps {
  form: UseFormReturn<BotFormData>;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function BotPictureField(props: BotFieldPictureProps) {
  const snackbar = useSnackbar();
  const {form} = props;
  const {control, formState, watch, trigger, setValue} = form;
  const {errors, isSubmitting} = formState;
  const error = errors?.picture;

  const picture = watch('picture');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
          const maxSize = 140; // Desired width and height
          let {width} = img;
          let {height} = img;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;
          context.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {type: file.type});
              const reader = new FileReader();
              reader.readAsDataURL(resizedFile);

              reader.onload = function () {
                setValue('picture', reader.result as string);
              };

              reader.onerror = function (error) {
                snackbar.showMessage(error.toString());
              };
            }
          }, file.type);
        }

        URL.revokeObjectURL(objectUrl);
      };

      img.onerror = () => {
        snackbar.showMessage('Invalid image file');
        URL.revokeObjectURL(objectUrl);
      };
    }
  };

  const onRemoveClick = () => {
    setValue('picture', undefined);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      {picture && <img alt="avatar" width="36" height="36" src={picture} style={{borderRadius: '18px'}} />}
      {picture && (
        <Button sx={{ml: 1, mr: 1}} variant="outlined" size="small" onClick={onRemoveClick}>
          Remove
        </Button>
      )}
      <Button
        component="label"
        role={undefined}
        variant="contained"
        size="small"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}>
        Upload
        <VisuallyHiddenInput type="file" accept=".png, .jpg, .jpeg" onChange={onChange} />
      </Button>
    </Box>
  );
}
