import React from 'react';

import IntegrationCard from '../../../base/components/IntegrationCard';
import MicrosoftCalendarIcon from '../../../base/icons/MicrosoftCalendarIcon';
import {IntegrationService} from '../../../base/types';
import {MICROSOFT_CALENDAR_SHORT_DESCRIPTION, MICROSOFT_CALENDAR_TITLE} from '../../../texts';
import {MicrosoftIntegrationPath} from '../types';

export default function MicrosoftIntegrationCard(props: any) {
  const {integrations} = props;

  return (
    <IntegrationCard
      icon={<MicrosoftCalendarIcon width={50} height={50} />}
      title={MICROSOFT_CALENDAR_TITLE}
      description={MICROSOFT_CALENDAR_SHORT_DESCRIPTION}
      href={MicrosoftIntegrationPath.base}
      integrations={integrations}
      service={IntegrationService.MICROSOFT_CALENDAR}
      apps={['x-bees', 'collaboration']}
    />
  );
}
