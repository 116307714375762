import * as React from 'react';
import {MutableRefObject} from 'react';

import CopyAllOutlined from '@mui/icons-material/CopyAllOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import copy from 'copy-to-clipboard';
import {useSnackbar} from 'material-ui-snackbar-provider';

interface IntegrationRemoveDialogApi {
  open: CallableFunction;
}

interface IntegrationRemoveDialogProps {
  apiRef: MutableRefObject<IntegrationRemoveDialogApi>;
}

export default function BotEditApiKeysViewDialog(props: IntegrationRemoveDialogProps) {
  const {apiRef} = props;
  const snackbar = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [secret, setSecret] = React.useState<string | undefined>();

  const onDialogClose = () => {
    setOpen(false);
  };

  const onCopyClick = () => {
    if (secret) {
      copy(secret);
      snackbar.showMessage('Copied.');
    }

    onDialogClose();
  };

  apiRef.current = {
    open: (secret: string) => {
      setSecret(`sk-${secret}`);
      setOpen(true);
    },
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onDialogClose}>
      <DialogTitle>Secret</DialogTitle>
      <DialogContent>
        <pre
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
            padding: '10px',
            background: '#f5f5f5',
            borderRadius: '12px',
          }}>
          {secret}
        </pre>
      </DialogContent>
      <DialogActions>
        <LoadingButton autoFocus onClick={onCopyClick} color="primary" startIcon={<CopyAllOutlined />}>
          Copy
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
