import {useQuery} from 'react-query';

import {GetBotCommand} from '@wildix/xbees-users-client';

import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';

export default function useGetBotQuery(botId?: string) {
  return useQuery(['chatbots', botId], () => getXbsUsersClient().send(new GetBotCommand({botId: botId || ''})), {
    retry: false,
    enabled: !!botId,
  });
}
