import React, {useCallback, useState} from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Avatar, Box, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography} from '@mui/material';

import copy from 'copy-to-clipboard';

export default function InstallationDescription() {
  const clientId = '111004272467246675231';
  const scopes =
    'https://www.googleapis.com/auth/calendar,https://www.googleapis.com/auth/calendar.events,https://www.googleapis.com/auth/admin.directory.user.readonly';

  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const onCopySnackbarClosed = useCallback(() => {
    setCopySnackbarOpen(false);
  }, []);

  const onClientIdCopyClick = useCallback(() => {
    copy(clientId);
    setCopySnackbarOpen(true);
  }, [clientId]);
  const onScopesCopyClick = useCallback(() => {
    copy(scopes);
    setCopySnackbarOpen(true);
  }, [scopes]);

  return (
    <Box mt={2}>
      <Grid container spacing={2} mt={1}>
        <Grid item>
          <Avatar alt="1" sx={{width: 32, height: 32, fontSize: '14px', background: '#7F8083'}}>
            1
          </Avatar>
        </Grid>
        <Grid item>
          <Typography color="text.primary" variant="h6">
            Domain-wide delegation
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography color="text.primary" variant="caption">
          <span>
            Access your Google Suite Admin console and paste the <b>Client ID</b> and <b>Scopes</b> into Security &gt;
            App access control &gt; Manage Domain Wide delegation.
          </span>
          <br />
          <a target="_blank" rel="noreferrer" href="https://admin.google.com/ac/owl/domainwidedelegation">
            https://admin.google.com/ac/owl/domainwidedelegation
          </a>
        </Typography>
      </Box>
      <Box sx={{width: '50%'}} mt={3}>
        <Box>
          <TextField
            size="small"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    size="small"
                    onClick={onClientIdCopyClick}>
                    <ContentCopyIcon sx={{width: 16, height: 16}} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
            fullWidth
            label="Client ID"
            id="outlined-required"
            value={clientId}
          />
        </Box>
        <Box mt={2}>
          <TextField
            size="small"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    size="small"
                    onClick={onScopesCopyClick}>
                    <ContentCopyIcon sx={{width: 16, height: 16}} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
            fullWidth
            label="Scopes"
            id="outlined-required"
            value={scopes}
          />
        </Box>
      </Box>
      <Snackbar open={copySnackbarOpen} autoHideDuration={3000} onClose={onCopySnackbarClosed} message="Copied" />
    </Box>
  );
}
