import React, {useCallback, useState} from 'react';

import Alert from '@mui/material/Alert';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import SalesforceCommunityIcon from '../../../base/icons/SalesforceCommunityIcon';
import {IntegrationService} from '../../../base/types';
import {SALESFORCE_COMMUNITY_DESCRIPTION, SALESFORCE_COMMUNITY_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';

export default function SalesforceCommunityIntegrationScreen() {
  const [warningMessage, setWarningMessage] = useState('');
  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, IntegrationService.SALESFORCE_COMMUNITY);
  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({
    onSuccess: (response) => {
      const {success, result} = response;

      if (!success) {
        setWarningMessage(result);
      } else {
        setWarningMessage('');
      }
    },
  });
  const showMessage = useCallback(() => {
    if (!integration && warningMessage) {
      return <Alert sx={{m: '20px 0 0 0'}} severity="warning" children={<div>{warningMessage}</div>} />;
    }
  }, [warningMessage, integration]);

  const enableIntegration = useCallback(() => {
    mutate({service: IntegrationService.SALESFORCE_COMMUNITY, data: {}});
  }, [mutate]);

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  return (
    <IntegrationScreen
      title={SALESFORCE_COMMUNITY_TITLE}
      description={SALESFORCE_COMMUNITY_DESCRIPTION}
      icon={<SalesforceCommunityIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={enableIntegration}
      isInstallLoading={isInstallationLoading}>
      {showMessage()}
    </IntegrationScreen>
  );
}
