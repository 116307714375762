export const WarningCode = {
  // MS Presence
  PROLONG_USER_SUBSCRIPTION_FAILED: 'prolong_user_subscription_failed',
  PROLONG_PRESENCE_SUBSCRIPTION_FAILED: 'prolong_presence_subscription_failed',
  SYNC_PRESENCE_FAILED: 'sync_presence_failed',
  SYNC_USERS_FAILED: 'sync_users_failed',
  WDA_PRESENCE_FAILED: 'wda_presence_failed',

  UNKNOWN_SUBSCRIPTION_TYPE: 'unknown_subscription_type',
  SUBSCRIPTIONS_NOT_FOUND: 'subscriptions_not_found',

  NO_PBX_USERS: 'no_pbx_users',
  BAD_TIMESTAMP: 'bad_timestamp',

  MISSING_EMAIL: 'missing_email',
};
