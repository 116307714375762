import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, Typography} from '@mui/material';

import {noop} from 'lodash';
import pick from 'lodash/pick';
import {useSnackbar} from 'material-ui-snackbar-provider';

import getRandomString from '../../../base/helpers/getRandomString';
import WebHookIcon from '../../../base/icons/WebHookIcon';
import {IntegrationService} from '../../../base/types';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';
import {WebhooksIntegrationPath} from '../constants';
import {TransmissionType, WebhookFormData} from '../types';
import WebHookIntegrationForm from './WebHookIntegrationForm';

export default function WebHookIntegrationAddScreen() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const form = useForm<WebhookFormData>({
    defaultValues: {
      type: TransmissionType.WEBHOOK,
      url: '',
      key: '',
      secretSqs: '',
      secretWebhook: getRandomString(),
      events: [],
    },
  });

  const addMutation = useIntegrationInstallationMutation({
    onSuccess: () => {
      navigate(`${WebhooksIntegrationPath.base}?mutate=true`);
    },
    onError: (error: Error) => {
      snackbar.showMessage(error.toString());
    },
  });
  const onSubmit = useCallback(
    async (data: WebhookFormData) => {
      const content = {
        ...pick(data, ['type', 'url', 'key']),
        ...(data.type === TransmissionType.SQS ? {secret: data.secretSqs} : {secret: data.secretWebhook}),
        events: data.events.map((key: string) => ({
          value: key,
        })),
      };

      await addMutation.mutateAsync({service: IntegrationService.WEBHOOK, data: content}).catch(noop);

      return;
    },
    [addMutation],
  );

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={WebhooksIntegrationPath.base}>
          Webhooks
        </Typography>
        <Typography color="text.primary">Add</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <WebHookIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5}>
          <Typography color="text.primary" variant="h5">
            New webhook integration
          </Typography>

          <WebHookIntegrationForm form={form} onSubmit={onSubmit} />
        </Box>
      </Box>
    </Box>
  );
}
