import {MAX_DESCRIPTION_FIELD_LENGTH, MAX_TITLE_FIELD_LENGTH} from '../constants';

export default function getRulesValidation(fieldName: string, value: string): boolean {
  switch (fieldName) {
    case 'title':
      return !!value && value.length > 1 && value.length < MAX_TITLE_FIELD_LENGTH;

    case 'iframeUrl':
      return /^https:\/\/.+$/i.test(value);

    case 'description':
      return value.length < MAX_DESCRIPTION_FIELD_LENGTH;

    case 'email':
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(value).toLowerCase(),
      );

    default:
      return false;
  }
}
