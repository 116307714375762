import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {BotFormData} from '../../types';

interface BotFieldNameProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotNameField(props: BotFieldNameProps) {
  const {form} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = errors?.name;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          id="outlined-required"
          label="Name"
          placeholder="Bot Name"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name="name"
      rules={{
        required: 'This field is required.',
        minLength: {
          value: 2,
          message: 'Name must be at least 2 characters long.',
        },
        maxLength: {
          value: 50,
          message: 'Name must be no more than 50 characters long.',
        },
        pattern: {
          value: /^[^^\\/\\@|+=}{\][)(*&?%$#@!,":;<>]{2,50}$/i,
          message:
            'Name contains invalid characters. Please avoid using special characters like \\ / @ | += }{ ][ )( * & ? % $ # ! , ": ; <>.',
        },
      }}
    />
  );
}
