import {useEffect, useState} from 'react';
import {useMutation} from 'react-query';

import {IntegrationService} from '../../base/types';
import {WIM_API} from '../../env';
import token from '../../token';

export default function useConsoleBlendrInstallationLink(service: IntegrationService) {
  const {isLoading, mutateAsync} = useMutation(() => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        service,
        data: {},
      }),
    };

    return fetch(`${WIM_API}/console/integrations`, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.status.toString());
        } else {
          return res.json();
        }
      })
      .then(({success, result, message}) => {
        if (!success) {
          throw new Error(message);
        }

        return result;
      });
  });
  const [link, setLink] = useState(null);

  useEffect(() => {
    mutateAsync()
      .then((res) => setLink(res.destination))
      .catch((err) => alert(err));
  }, [mutateAsync]);

  return {link, isLoading};
}
