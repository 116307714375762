import {IntegrationService} from '../../base/types';

export enum HubspotBackendIntegrationPath {
  base = '/hubspot_backend',
}

export interface HubspotBackendIntegrationInstallProps {
  service: IntegrationService.HUBSPOT_BACKEND;
  data: any;
}
