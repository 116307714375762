import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Grid, Paper, Tooltip} from '@mui/material';

import useConsoleIntegrationLogs from '../../../integrations/hooks/useConsoleIntegrationLogs';
import {LOG_LIST_SIZE} from '../../constants/constants';
import fetchLogs from '../../helpers/fetchLogs';
import {formatFullLog} from '../../helpers/formatLog';
import {Integration, IntegrationLog} from '../../types';
import ApiErrorBox from '../error/ApiErrorBox';
import LoadingBox from '../LoadingBox';
import IntegrationLogItem from './integrationLogItem';

interface IntegrationLogsProps {
  integration: Integration;
}

export default function IntegrationLogContainer({integration: {integrationId}}: IntegrationLogsProps) {
  const {isLoading, isError, error, data: {logs = []} = {}} = useConsoleIntegrationLogs(integrationId);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<string>('Copy logs');
  const [printedLogs, setPrintedLogs] = useState<IntegrationLog[]>([]);

  const {featurePolicy} = document as any;
  const isCopyingEnabled = Boolean(featurePolicy?.allowsFeature?.('clipboard-write'));

  useEffect(() => {
    setPrintedLogs([...printedLogs, ...logs]);
    setHasMore(!!logs?.length);
  }, [logs]);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(formatFullLog(integrationId, printedLogs));
    setTooltip('Copied!');
  };

  const handleTooltipClose = () => setTimeout(() => setTooltip('Copy logs'), 500);

  const loadMore = async (page: number) => {
    const logsResponse = await fetchLogs(integrationId, page * LOG_LIST_SIZE);

    if (!logsResponse) {
      return setHasMore(false);
    }

    const {logs} = logsResponse;

    if (logs?.length) {
      setPrintedLogs([...printedLogs, ...logs]);
    }

    setHasMore(!!logs?.length);
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  return (
    <Paper
      sx={{
        marginTop: '18px',
        padding: '16px 18px',
      }}>
      <Grid sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
        <Grid sx={{fontWeight: '500', fontFamily: 'Roboto', fontSize: '16px', lineHeight: '20px', color: '#292C31'}}>
          Logs for IntegrationID: {integrationId}
        </Grid>
        {isCopyingEnabled ? (
          <Tooltip title={tooltip} placement="bottom-start" onClose={handleTooltipClose}>
            <ContentCopyIcon onClick={handleCopyClick} sx={{':hover': {cursor: 'pointer', color: '#3F9FD6'}}} />
          </Tooltip>
        ) : null}
      </Grid>
      {printedLogs.length ? (
        <Grid
          sx={{
            padding: '14px',
            backgroundColor: '#F5F6F7',
            borderRadius: '4px',
            maxHeight: '300px',
            overflowY: 'scroll',
          }}>
          <InfiniteScroll pageStart={0} useWindow={false} hasMore={hasMore} loadMore={loadMore}>
            {printedLogs.map((i: IntegrationLog, j: number) => (
              <IntegrationLogItem integrationLog={i} key={j} />
            ))}
          </InfiniteScroll>
        </Grid>
      ) : (
        <Grid>There are no logs yet</Grid>
      )}
    </Paper>
  );
}
