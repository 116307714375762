import {IntegrationService} from '../../base/types';

export interface GoogleCalendarInstallProps {
  service: IntegrationService.GOOGLE_CALENDAR;
  data: {
    administrator: string;
    domain: string;
  };
}

export enum GoogleIntegrationPath {
  base = '/google/calendar',
  install = '/google/calendar/install',
}
