export const statusStyles = {
  padding: '8px',
  borderRadius: '8px',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: '500',
  alignItems: 'center',
  display: 'flex',
  maxHeight: '37px',
};
