import React, {useCallback, useState} from 'react';

import {Box, Grid} from '@mui/material';

import LoadingBox from '../../../base/components/LoadingBox';
import {IntegrationService} from '../../../base/types';
import {MAX_NUMBER_INSTALLED_INTEGRATIONS, PREPARED_PUBLIC_INTEGRATION} from '../constants';
import {IframeIntegration, IframeIntegrationIdTemplate} from '../types';
import IframeIntegrationPreparedItem from './IframeIntegrationPreparedItem';

export interface IframeIntegrationsProps {
  integrations: IframeIntegration[];
  isCILoading: boolean;
  isDeleteLoading: boolean;
  isInstallationLoading: boolean;
  sendInstallIntegration: Function;
  sendUninstallIntegration: (integrationId: string) => unknown;
}

function IframeIntegrationsPreparedList(props: IframeIntegrationsProps) {
  const {
    integrations,
    isCILoading,
    isDeleteLoading,
    isInstallationLoading,
    sendInstallIntegration,
    sendUninstallIntegration,
  } = props;
  const [currentTemplateId, setCurrentTemplateId] = useState<any>('');
  const handleIntegration = useCallback(
    (templateId: IframeIntegrationIdTemplate, integrationId: string) => {
      const foundPreparedIntegration = PREPARED_PUBLIC_INTEGRATION.find(({config}) => config.templateId === templateId);

      if (!foundPreparedIntegration) {
        console.warn(`An Integration (${templateId}) not registered`);

        return null;
      }

      setCurrentTemplateId(templateId);

      if (integrationId) {
        sendUninstallIntegration(integrationId);
      } else {
        sendInstallIntegration({service: IntegrationService.IFRAME_INTEGRATION, data: foundPreparedIntegration.config});
      }

      return null;
    },
    [sendInstallIntegration, sendUninstallIntegration],
  );

  const getIntegrationIdByTemplateId = useCallback(
    (templateId: IframeIntegrationIdTemplate): string => {
      const found: IframeIntegration | undefined = integrations.find(
        ({data: {templateId: currentTemplateId}}: IframeIntegration) => templateId === currentTemplateId,
      );

      if (found) {
        return found.integrationId;
      }

      return '';
    },
    [integrations],
  );

  if (isCILoading) {
    return (
      <Box sx={{justifyContent: 'center'}}>
        <LoadingBox />
      </Box>
    );
  }

  return (
    <Grid container>
      {PREPARED_PUBLIC_INTEGRATION.map((integration) => {
        const {
          config,
          config: {templateId},
          properties: {apps, Icon},
        } = integration;

        return (
          <IframeIntegrationPreparedItem
            key={templateId}
            source={config}
            apps={apps}
            isMaxInstalledIntegrations={integrations.length >= MAX_NUMBER_INSTALLED_INTEGRATIONS}
            isLoading={isInstallationLoading || isDeleteLoading || isCILoading}
            currentTemplateId={currentTemplateId}
            handleIntegration={handleIntegration}
            integrationId={getIntegrationIdByTemplateId(templateId)}
            icon={<Icon height="25px" width="25px" />}
          />
        );
      })}
    </Grid>
  );
}

export default IframeIntegrationsPreparedList;
