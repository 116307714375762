import React, {useEffect, useState} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {Box, Grid} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {SnackbarProvider} from 'material-ui-snackbar-provider';

import ApiErrorBox from './base/components/error/ApiErrorBox';
import AuthorizationFailureBox from './base/components/error/AuthorizationFailureBox';
import LoadingBox from './base/components/LoadingBox';
import isAllowedService from './base/helpers/isAllowedService';
import {Integration, IntegrationService} from './base/types';
import BotAddScreen from './integrations/chatbots/components/BotAddScreen';
import BotEditApiKeysScreen from './integrations/chatbots/components/BotEditApiKeysScreen';
import BotEditScreen from './integrations/chatbots/components/BotEditScreen';
import BotsCard from './integrations/chatbots/components/BotsCard';
import BotsScreen from './integrations/chatbots/components/BotsScreen';
import {BotsPath} from './integrations/chatbots/constants';
import GongIntegrationCard from './integrations/gong/components/GongIntegrationCard';
import GongIntegrationScreen from './integrations/gong/components/GongIntegrationScreen';
import {GongIntegrationPath} from './integrations/gong/types';
import GoogleCalendarInstallationScreen from './integrations/google/components/GoogleCalendarInstallationScreen';
import GoogleIntegrationCard from './integrations/google/components/GoogleIntegrationCard';
import GoogleIntegrationScreen from './integrations/google/components/GoogleIntegrationScreen';
import {GoogleIntegrationPath} from './integrations/google/types';
import useConsoleIntegrations from './integrations/hooks/useConsoleIntegrations';
import useConsoleIntegrationStatusWatching from './integrations/hooks/useConsoleIntegrationStatusWatching';
import HubspotBackendIntegrationCard from './integrations/hubspot_backend/components/HubspotBackendIntegrationCard';
import HubspotBackendIntegrationScreen from './integrations/hubspot_backend/components/HubspotBackendIntegrationScreen';
import {HubspotBackendIntegrationPath} from './integrations/hubspot_backend/types';
import IframeIntegrationCard from './integrations/iframeintegrations/components/IframeIntegrationCard';
import IframeIntegrationScreen from './integrations/iframeintegrations/components/IframeIntegrationScreen';
import {IframeIntegrationPath} from './integrations/iframeintegrations/types';
import MicrosoftAuthPopup from './integrations/microsoft365/components/MicrosoftAuthPopup';
import MicrosoftIntegrationCard from './integrations/microsoft365/components/MicrosoftIntegrationCard';
import MicrosoftIntegrationScreen from './integrations/microsoft365/components/MicrosoftIntegrationScreen';
import {MicrosoftIntegrationPath} from './integrations/microsoft365/types';
import MicrosoftPresenceAuthPopup from './integrations/microsoftpresence/components/MicrosoftPresenceAuthPopup';
import MicrosoftPresenceIntegrationCard from './integrations/microsoftpresence/components/MicrosoftPresenceIntegrationCard';
import MicrosoftPresenceIntegrationScreen from './integrations/microsoftpresence/components/MicrosoftPresenceIntegrationScreen';
import {MicrosoftPresenceIntegrationPath} from './integrations/microsoftpresence/types';
import SalesforceBackendIntegrationCard from './integrations/salesforce_backend/components/SalesforceBackendIntegrationCard';
import SalesforceBackendIntegrationScreen from './integrations/salesforce_backend/components/SalesforceBackendIntegrationScreen';
import {SalesforceBackendIntegrationPath} from './integrations/salesforce_backend/types';
import SalesforceTelephonyIntegrationCard from './integrations/salesforce_telephony/components/SalesforceTelephonyIntegrationCard';
import SalesforceTelephonyIntegrationScreen from './integrations/salesforce_telephony/components/SalesforceTelephonyIntegrationScreen';
import {SalesforceTelephonyIntegrationPath} from './integrations/salesforce_telephony/types';
import SalesforceCommunityInstallationScreen from './integrations/salesforcecommunity/components/SalesforceCommunityInstallationScreen';
import SalesforceCommunityIntegrationCard from './integrations/salesforcecommunity/components/SalesforceCommunityIntegrationCard';
import SalesforceCommunityIntegrationScreen from './integrations/salesforcecommunity/components/SalesforceCommunityIntegrationScreen';
import {SalesforceCommunityIntegrationPath} from './integrations/salesforcecommunity/types';
import WebHookIntegrationAddScreen from './integrations/webhook/components/WebHookIntegrationAddScreen';
import WebHookIntegrationCard from './integrations/webhook/components/WebHookIntegrationCard';
import WebHookIntegrationEditScreen from './integrations/webhook/components/WebHookIntegrationEditScreen';
import WebHookIntegrationScreen from './integrations/webhook/components/WebHookIntegrationScreen';
import {WebhooksIntegrationPath} from './integrations/webhook/constants';
import token from './token';

const theme = createTheme({});
const queryClient = new QueryClient();

function ConsoleHome() {
  const {
    isLoading,
    isError,
    error,
    data: {installed = [], allowed: allowedServicesForCompany = []} = {},
  } = useConsoleIntegrations();
  const [installedServices, setInstalledServices] = useState<Integration[]>(installed);
  const {watch, unwatch} = useConsoleIntegrationStatusWatching(setInstalledServices);

  useEffect(() => {
    setInstalledServices(installed);
    watch(installed);
  }, [installed, watch]);

  useEffect(() => () => unwatch(), [unwatch]);

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  if (allowedServicesForCompany.length === 0) {
    return <ApiErrorBox error="You don't have available integrations, need to have at least 1 business+ license" />;
  }

  // [WMS-19208] This fix should be removed about in a month
  // @ts-ignore
  const installedIntegrationsServices = installedServices.map(({service}) => service);
  const temporaryAllowedForUcLicences = Array.from<string>(
    new Set(allowedServicesForCompany.concat(installedIntegrationsServices)),
  );

  return (
    <Grid container>
      {isAllowedService(allowedServicesForCompany, IntegrationService.IFRAME_INTEGRATION) ? (
        <IframeIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(allowedServicesForCompany, IntegrationService.GOOGLE_CALENDAR) ? (
        <GoogleIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(allowedServicesForCompany, IntegrationService.MICROSOFT_CALENDAR) ? (
        <MicrosoftIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(allowedServicesForCompany, IntegrationService.MICROSOFT_PRESENCE) ? (
        <MicrosoftPresenceIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(temporaryAllowedForUcLicences, IntegrationService.SALESFORCE_BACKEND) ? (
        <SalesforceBackendIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(allowedServicesForCompany, IntegrationService.SALESFORCE_COMMUNITY) ? (
        <SalesforceCommunityIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(temporaryAllowedForUcLicences, IntegrationService.SALESFORCE_TELEPHONY) ? (
        <SalesforceTelephonyIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(temporaryAllowedForUcLicences, IntegrationService.HUBSPOT_BACKEND) ? (
        <HubspotBackendIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(allowedServicesForCompany, IntegrationService.WEBHOOK) ? (
        <WebHookIntegrationCard integrations={installedServices} />
      ) : null}
      {isAllowedService(allowedServicesForCompany, IntegrationService.GONG) ? (
        <GongIntegrationCard integrations={installedServices} />
      ) : null}
      <BotsCard />
    </Grid>
  );
}

function Console() {
  if (!token && !window.opener) {
    return (
      <ThemeProvider theme={theme}>
        <AuthorizationFailureBox />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <BrowserRouter>
            <Box p={1} sx={{maxWidth: '1240px'}}>
              <Routes>
                <Route path="/" element={<ConsoleHome />} />
                <Route path={GoogleIntegrationPath.install} element={<GoogleCalendarInstallationScreen />} />
                <Route path={GoogleIntegrationPath.base} element={<GoogleIntegrationScreen />} />
                <Route path={MicrosoftIntegrationPath.base} element={<MicrosoftIntegrationScreen />} />
                <Route path={MicrosoftIntegrationPath.callback} element={<MicrosoftAuthPopup />} />
                <Route
                  path={SalesforceCommunityIntegrationPath.base}
                  element={<SalesforceCommunityIntegrationScreen />}
                />
                <Route
                  path={SalesforceCommunityIntegrationPath.install}
                  element={<SalesforceCommunityInstallationScreen />}
                />
                <Route path={MicrosoftPresenceIntegrationPath.base} element={<MicrosoftPresenceIntegrationScreen />} />
                <Route path={MicrosoftPresenceIntegrationPath.callback} element={<MicrosoftPresenceAuthPopup />} />
                <Route path={WebhooksIntegrationPath.base} element={<WebHookIntegrationScreen />} />
                <Route path={WebhooksIntegrationPath.install} element={<WebHookIntegrationAddScreen />} />
                <Route path={WebhooksIntegrationPath.edit} element={<WebHookIntegrationEditScreen />} />
                <Route path={IframeIntegrationPath.base} element={<IframeIntegrationScreen />} />
                <Route path={HubspotBackendIntegrationPath.base} element={<HubspotBackendIntegrationScreen />} />
                <Route path={SalesforceBackendIntegrationPath.base} element={<SalesforceBackendIntegrationScreen />} />
                <Route
                  path={SalesforceTelephonyIntegrationPath.base}
                  element={<SalesforceTelephonyIntegrationScreen />}
                />
                <Route path={GongIntegrationPath.base} element={<GongIntegrationScreen />} />
                <Route path={BotsPath.add} element={<BotAddScreen />} />
                <Route path={BotsPath.edit} element={<BotEditScreen />} />
                <Route path={BotsPath.editApiKeys} element={<BotEditApiKeysScreen />} />
                <Route path={BotsPath.base} element={<BotsScreen />} />
              </Routes>
            </Box>
          </BrowserRouter>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default Console;
