import React from 'react';

import {SvgIcon, SvgIconProps} from '@mui/material';

export default function PencilIcon20(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0299 4.89951C15.8564 4.89951 15.6847 4.93367 15.5245 5.00003C15.3643 5.0664 15.2187 5.16368 15.0961 5.28631L14.9115 5.47088L16.7791 7.33852L16.9637 7.15394C17.0863 7.03131 17.1836 6.88573 17.25 6.7255C17.3163 6.56528 17.3505 6.39355 17.3505 6.22013C17.3505 6.0467 17.3163 5.87497 17.25 5.71475C17.1836 5.55452 17.0863 5.40894 16.9637 5.28631C16.8411 5.16368 16.6955 5.0664 16.5353 5.00003C16.375 4.93367 16.2033 4.89951 16.0299 4.89951ZM15.0368 3.82254C15.3516 3.69212 15.6891 3.625 16.0299 3.625C16.3707 3.625 16.7081 3.69212 17.023 3.82254C17.3378 3.95296 17.6239 4.14412 17.8649 4.38509C18.1059 4.62607 18.297 4.91216 18.4275 5.22701C18.5579 5.54187 18.625 5.87933 18.625 6.22013C18.625 6.56092 18.5579 6.89838 18.4275 7.21324C18.297 7.52809 18.1059 7.81418 17.8649 8.05516L17.2297 8.69034C17.1102 8.80984 16.9481 8.87698 16.7791 8.87698C16.6101 8.87698 16.448 8.80984 16.3285 8.69034L13.5597 5.92149C13.3108 5.67262 13.3108 5.26914 13.5597 5.02027L14.1948 4.38509C14.4358 4.14412 14.7219 3.95296 15.0368 3.82254ZM12.1014 6.56619C12.2718 6.56512 12.4355 6.63234 12.556 6.75282L15.3249 9.52167C15.5715 9.76834 15.574 10.1675 15.3305 10.4172L8.52564 17.3944C8.44632 17.4757 8.34671 17.5343 8.23711 17.5642L4.42994 18.6025C4.20932 18.6627 3.97337 18.6 3.81166 18.4383C3.64996 18.2766 3.5873 18.0407 3.64747 17.8201L4.68579 14.0129C4.71426 13.9085 4.76884 13.8131 4.84438 13.7356L11.6492 6.75849C11.7682 6.63651 11.931 6.56725 12.1014 6.56619ZM12.1111 8.11031L5.87198 14.5074L5.1705 17.0795L7.73437 16.3803L13.9786 9.97788L12.1111 8.11031Z"
        fill="#9D9FA2"
      />
    </SvgIcon>
  );
}
