import React from 'react';

import {Box, CircularProgress} from '@mui/material';

export default function LoadingBox() {
  return (
    <Box p={2}>
      <CircularProgress size={24} />
    </Box>
  );
}
