import React from 'react';

import {LoadingButton} from '@mui/lab';
import {Avatar, Box, Grid, Typography} from '@mui/material';

import InstallationFieldAdministrator from './InstallationFieldAdministrator';
import InstallationFieldDomain from './InstallationFieldDomain';

export default function InstallationForm(props: any) {
  const {form, onSubmit} = props;
  const {handleSubmit, formState} = form;
  const {isSubmitting} = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={4}>
        <Grid container spacing={2} mt={1}>
          <Grid item>
            <Avatar alt="1" sx={{width: 32, height: 32, fontSize: '14px', background: '#7F8083'}}>
              2
            </Avatar>
          </Grid>
          <Grid item>
            <Typography color="text.primary" variant="h6">
              Connect the integration
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Box mt={2} sx={{width: '50%'}}>
            <InstallationFieldAdministrator form={form} />
          </Box>
          <Typography mt={1} variant="caption" display="block" gutterBottom>
            * Specify an <b>Administrator email</b> which have an access to Google Workspace, it is necessary to take a
            list of users of your organization.
          </Typography>
          <Box mt={2} sx={{width: '50%'}}>
            <InstallationFieldDomain form={form} />
          </Box>
          <Typography mt={1} variant="caption" display="block">
            * Specify <b>Primary domain</b> of an organization which indicated in Google Workspace, see{' '}
            <a target="_blank" rel="noreferrer" href="https://admin.google.com/ac/domains/manage">
              https://admin.google.com/ac/domains/manage
            </a>{' '}
            for more details.
          </Typography>
        </Box>
      </Box>
      <Box mt={3}>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
          Install
        </LoadingButton>
      </Box>
    </form>
  );
}
