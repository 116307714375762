import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

import CancelButton from '../../../base/components/button/CancelButton';
import SqsIcon24 from '../../../base/icons/SqsIcon24';
import WebhookIcon24 from '../../../base/icons/WebhookIcon24';
import {WEBHOOK_EVENTS, WebhooksIntegrationPath} from '../constants';
import {TransmissionType, WebhookFormData} from '../types';
import WebhookEventsAccordion from './fields/WebhookEventsAccordion';
import WebhookFieldHttpSecret from './fields/WebhookFieldHttpSecret';
import WebhookFieldHttpUrl from './fields/WebhookFieldHttpUrl';
import WebhookFieldSqsKey from './fields/WebhookFieldSqsKey';
import WebhookFieldSqsSecret from './fields/WebhookFieldSqsSecret';
import WebhookFieldSqsUrl from './fields/WebhookFieldSqsUrl';

interface WebHookIntegrationFormProps {
  // TODO
  form: UseFormReturn<WebhookFormData>;
  onSubmit: (data: WebhookFormData) => void;
}

export default function WebHookIntegrationForm(props: WebHookIntegrationFormProps) {
  const navigate = useNavigate();
  const {form, onSubmit} = props;
  const {handleSubmit, formState, watch, setValue} = form;
  const {isSubmitting, errors} = formState;

  const id = watch('id');
  const type = watch('type');

  const onTypeChange = (e: React.MouseEvent<HTMLElement>, value: unknown) => {
    if (value) {
      setValue('type', value as TransmissionType);
    }
  };

  const onCancelClick = () => {
    navigate(WebhooksIntegrationPath.base);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={4}>
        <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
          Select the integration type to distribute events.
        </Typography>
        <Box mt={2}>
          <ToggleButtonGroup disabled={isSubmitting} size="medium" exclusive value={type} onChange={onTypeChange}>
            <ToggleButton value={TransmissionType.WEBHOOK}>
              <WebhookIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                Webhook
              </Box>
            </ToggleButton>
            <ToggleButton value={TransmissionType.SQS}>
              <SqsIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                AWS SQS
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {type === TransmissionType.WEBHOOK && (
          <>
            <Box mt={4} width="75%">
              <WebhookFieldHttpUrl form={form} />
              <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                * Specified URL will be used by the integration to send POST requests with the event payload.
              </Typography>
            </Box>
            <Box mt={3} width="75%">
              <WebhookFieldHttpSecret form={form} />
              <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                * A secret verifies incoming HTTP requests are from the Wildix system.
              </Typography>
            </Box>
            <Alert severity="info" sx={{mt: 3}}>
              The endpoint should be publicly accessible with a valid HTTPS certificate, accept HTTP POST requests with
              JSON payloads, respond with codes from 200 to 299 as quickly as possible, and handle multiple identical
              calls in case of retries due to network or server failures (up to 3 times).
            </Alert>
          </>
        )}
        {type === TransmissionType.SQS && (
          <>
            <Box mt={4} width="75%">
              <WebhookFieldSqsUrl form={form} />
            </Box>
            <Box mt={3} width="75%">
              <WebhookFieldSqsKey form={form} />
              <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                * Specify access key id that will be used to sign request to the SQS.
              </Typography>
            </Box>
            <Box mt={3} width="75%">
              <WebhookFieldSqsSecret form={form} />
              <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                * Specify secret access key that will be used to sign request to the SQS.
              </Typography>
            </Box>
          </>
        )}
      </Box>

      <Box mt={4}>
        <Typography sx={{fontSize: '16px', fontWeight: '500'}}>
          Select a list of events that integration will transmit.
        </Typography>

        <Box mt={2}>
          {WEBHOOK_EVENTS.map(({key, label, events, deprecated}) => (
            <WebhookEventsAccordion key={key} form={form} label={label} events={events} deprecated={deprecated} />
          ))}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={6}>
        <CancelButton
          sx={{width: '96px'}}
          disabled={isSubmitting}
          onClick={onCancelClick}
          variant="contained"
          disableElevation
          size="medium">
          Cancel
        </CancelButton>
        <LoadingButton
          loading={isSubmitting}
          sx={{ml: 2, width: '96px'}}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          size="medium">
          {id ? 'Save' : 'Add'}
        </LoadingButton>
      </Box>
    </form>
  );
}
