import {useQuery} from 'react-query';

import {ListBotApiKeysCommand} from '@wildix/xbees-users-client';

import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';

export default function useListBotApiKeysQuery(botId?: string) {
  return useQuery(
    ['chatbots', botId, 'apikeys'],
    () => getXbsUsersClient().send(new ListBotApiKeysCommand({botId: botId || ''})),
    {
      retry: false,
      enabled: !!botId,
    },
  );
}
