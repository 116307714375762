import React from 'react';

import {Box, Typography} from '@mui/material';

import ApiError from '../../errors/ApiError';
import AuthorizationFailureBox from './AuthorizationFailureBox';

interface ApiErrorBoxProps {
  error: any;
}

function ApiErrorBox(props: ApiErrorBoxProps) {
  const {error} = props;
  const message = error.toString();

  if ('$metadata' in error && 'httpStatusCode' in error.$metadata && error.$metadata.httpStatusCode === 401) {
    return <AuthorizationFailureBox />;
  }

  if (error instanceof ApiError) {
    if (error.response.status === 401) {
      return <AuthorizationFailureBox />;
    }
  }

  return (
    <Box m={2} sx={{padding: 1, pl: 2, pr: 2, border: '1px solid #ccc', borderRadius: '6px', display: 'inline-block'}}>
      <Typography color="text.primary" variant="caption">
        {message}
      </Typography>
    </Box>
  );
}

export default ApiErrorBox;
