import React, {useCallback} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import {Alert, Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import getIntegrationsByService from '../../../base/helpers/getIntegrationsByService';
import WebHookIcon from '../../../base/icons/WebHookIcon';
import {IntegrationService} from '../../../base/types';
import {WEBHOOK_DESCRIPTION, WEBHOOK_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import {WebhooksIntegrationPath} from '../constants';
import WebhookListItem from './list/WebhookListItem';

export default function WebHookIntegrationScreen() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();

  const integrations = getIntegrationsByService(installed, IntegrationService.WEBHOOK);

  const onAddClick = useCallback(() => {
    navigate(WebhooksIntegrationPath.install);
  }, [navigate]);

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  const isMutateAppliedRecently = searchParams.get('mutate') === 'true';

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary">{WEBHOOK_TITLE}</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <WebHookIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5}>
          <Typography color="text.primary" variant="h5">
            {WEBHOOK_TITLE}
          </Typography>
          <Typography color="text.primary" sx={{paddingTop: 2}}>
            {WEBHOOK_DESCRIPTION}
          </Typography>

          {isLoading && (
            <Box mt={3}>
              <CircularProgress size={24} />
            </Box>
          )}
          {!isLoading && (
            <>
              <Button sx={{mt: 2}} onClick={onAddClick} variant="contained" size="medium">
                Add new integration
              </Button>

              {isMutateAppliedRecently && (
                <Alert severity="info" sx={{mt: 4}}>
                  Webhooks integrations could take up to 1 minute to apply the changes.
                </Alert>
              )}
              <Box mt={4}>
                {integrations.map((integration) => (
                  <WebhookListItem key={integration.integrationId} item={integration} />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
