import React, {useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import ChatBotsIcon from '../../../base/icons/ChatBotsIcon';
import {CHATBOTS_DESCRIPTION, CHATBOTS_TITLE} from '../../../texts';
import {BotsPath} from '../constants';
import useListBotsQuery from '../hooks/useListBotsQuery';
import BotListItem from './list/BotListItem';

export default function BotsScreen() {
  const navigate = useNavigate();
  const query = useListBotsQuery();

  const onAddClick = useCallback(() => {
    navigate(BotsPath.add);
  }, [navigate]);

  if (query.isError) {
    return <ApiErrorBox error={query.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary">{CHATBOTS_TITLE}</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <ChatBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            Chat Bots
          </Typography>
          <Typography color="text.primary" sx={{paddingTop: 2}}>
            {CHATBOTS_DESCRIPTION}
          </Typography>
          <Button sx={{mt: 2}} onClick={onAddClick} disabled={query.isLoading} variant="contained" size="medium">
            Add new chatbot
          </Button>
          <Box mt={4}>
            {query.isLoading && <CircularProgress size={24} />}
            {query.isSuccess ? query.data.bots.map((bot) => <BotListItem key={bot.id} bot={bot} />) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
