import GoogleIcon from '../../base/icons/GoogleIcon';
import HubspotIframeIcon from '../../base/icons/HubspotIframeIcon';
import MicrosoftCalendarIcon from '../../base/icons/MicrosoftCalendarIcon';
import SalesforceIframeIcon from '../../base/icons/SalesforceIframeIcon';
import {IntegrationService} from '../../base/types';
import {GOOGLE_URL, HUBSPOT_URL, MICROSOFT_URL, SALESFORCE_URL} from './env';
import {AvailablePlatforms, IframeIntegrationIdTemplate} from './types';

export const DEFAULT_TEMPLATE_FOR_CUSTOM_INTEGRATION = {
  integrationId: '',
  templateId: '',
  service: IntegrationService.IFRAME_INTEGRATION,
  blendId: '',
  externalId: '',
  data: {
    isNew: true,
    title: '',
    iframeUrl: '',
    description: '',
    usePbxToken: false,
    visibleInUi: true,
    position: 0,
    templateId: '',
    isMandatoryToUse: true,
    platforms: AvailablePlatforms.ALL,
    accessEmails: [],
    deniedEmails: [],
    isPreparedIntegration: false,
  },
};

export const MAX_TITLE_FIELD_LENGTH = 30;
export const MAX_DESCRIPTION_FIELD_LENGTH = 500;

export const WARNING_MESSAGE_TIME_DELAY_FOR_HIDE = 5;

export const MAX_NUMBER_INSTALLED_INTEGRATIONS = 10;

const DEFAULT_TEMPLATE = {
  usePbxToken: true,
  visibleInUi: true,
  isNew: true,
  isMandatoryToUse: false,
  platforms: AvailablePlatforms.ALL,
  position: 0,
  accessEmails: [],
  deniedEmails: [],
  isPreparedIntegration: true,
};

const HUBSPOT_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    templateId: IframeIntegrationIdTemplate.HUBSPOT,
    title: 'HubSpot',
    iframeUrl: HUBSPOT_URL,
    description:
      'Enables HubSpot integration with x-bees, allowing you to view HubSpot contact data on the Info frame in x-bees, see HubSpot contacts in Live and Dialpad search and create tasks and new HubSpot contacts via x-bees. For contact lookup, make sure you enable HubSpot Auto Logging.',
  },
  properties: {
    Icon: HubspotIframeIcon,
    apps: ['x-bees'],
  },
};

const SALESFORCE_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    templateId: IframeIntegrationIdTemplate.SALESFORCE,
    title: 'Salesforce',
    iframeUrl: SALESFORCE_URL,
    description:
      'Enables Salesforce integration with x-bees, allowing you to view Salesforce lead data on the Info frame in x-bees, see Salesforce contacts in Live and Dialpad search and create tasks and new Salesforce leads via x-bees. For contact lookup, make sure you enable Salesforce Auto Logging.',
  },
  properties: {
    Icon: SalesforceIframeIcon,
    apps: ['x-bees'],
  },
};

const MICROSOFT_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    templateId: IframeIntegrationIdTemplate.MICROSOFT,
    title: 'Microsoft 365',
    iframeUrl: MICROSOFT_URL,
    description:
      'Enables Microsoft 365 integration with x-bees, allowing you to see Microsoft 365 contacts in Live and Dialpad search, as well as view and open the latest emails with the contact via Info frame in x-bees.',
  },
  properties: {
    Icon: MicrosoftCalendarIcon,
    apps: ['x-bees'],
  },
};

const GOOGLE_TEMPLATE = {
  config: {
    ...DEFAULT_TEMPLATE,
    usePbxToken: false,
    isMandatoryToUse: true,
    templateId: IframeIntegrationIdTemplate.GOOGLE,
    title: 'Google Workspace',
    iframeUrl: GOOGLE_URL,
    description:
      'View, add, and edit Google Workspace Contacts from x-bees, see ongoing and upcoming calendar events with your contacts, and look up contacts in Live Search.',
  },
  properties: {
    Icon: GoogleIcon,
    apps: ['x-bees'],
  },
};

export const PREPARED_PUBLIC_INTEGRATION = [HUBSPOT_TEMPLATE, SALESFORCE_TEMPLATE, MICROSOFT_TEMPLATE, GOOGLE_TEMPLATE];
