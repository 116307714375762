import React from 'react';

import {SvgIcon, SvgIconProps} from '@mui/material';

export default function CopyIcon24(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M13.6892 7.86677L7.8541 7.86677C7.0806 7.86767 6.33904 8.16656 5.79209 8.69789C5.24514 9.22921 4.93746 9.94958 4.93653 10.701L4.93653 16.3694C4.93746 17.1208 5.24514 17.8412 5.79209 18.3725C6.33904 18.9038 7.0806 19.2027 7.8541 19.2036L13.6892 19.2036C14.4627 19.2027 15.2043 18.9038 15.7512 18.3725C16.2982 17.8412 16.6059 17.1208 16.6068 16.3694L16.6068 10.701C16.6059 9.94958 16.2982 9.22921 15.7512 8.69789C15.2043 8.16656 14.4627 7.86767 13.6892 7.86677ZM7.8541 18.0699C7.38983 18.0699 6.94457 17.8908 6.61628 17.5719C6.28799 17.2529 6.10356 16.8204 6.10356 16.3694L6.10356 10.701C6.10356 10.25 6.28799 9.81744 6.61628 9.49853C6.94457 9.17962 7.38983 9.00046 7.8541 9.00046L13.6892 9.00046C14.1535 9.00046 14.5988 9.17962 14.9271 9.49853C15.2553 9.81744 15.4398 10.25 15.4398 10.701L15.4398 16.3694C15.4398 16.8204 15.2553 17.2529 14.9271 17.5719C14.5988 17.8908 14.1535 18.0699 13.6892 18.0699L7.8541 18.0699ZM19.3105 8.03782L19.3105 15.4068C19.3105 15.5571 19.249 15.7013 19.1396 15.8076C19.0302 15.9139 18.8818 15.9736 18.727 15.9736C18.5722 15.9736 18.4238 15.9139 18.3144 15.8076C18.205 15.7013 18.1435 15.5571 18.1435 15.4068L18.1435 8.03782C18.1435 7.58682 17.9591 7.15428 17.6308 6.83537C17.3025 6.51646 16.8572 6.3373 16.3929 6.3373L8.80726 6.3373C8.65251 6.3373 8.50409 6.27758 8.39466 6.17127C8.28523 6.06497 8.22375 5.92079 8.22375 5.77046C8.22375 5.62012 8.28523 5.47594 8.39466 5.36964C8.50409 5.26333 8.65251 5.20361 8.80726 5.20361L16.3929 5.20361C17.1664 5.20451 17.908 5.50341 18.455 6.03473C19.0019 6.56605 19.3096 7.28642 19.3105 8.03782Z"
        fill="#77797C"
      />
    </SvgIcon>
  );
}
