import React from 'react';

import {SvgIcon, SvgIconProps} from '@mui/material';

export default function SqsIcon24(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M20.5672 8.4856L20.3008 8.4808L12.2896 6.1096L12.2848 6V2L20.568 6.1424L20.5672 8.4856Z"
        fill="#D9A741"
      />
      <path
        d="M12.284 6.2392L12.2848 2L4.0008 6.1408L4 17.856L4.004 17.8584L12.2832 21.9984L12.3104 21.96L12.3 18.0208L12.284 17.9992L11.7664 17.6304L5.3592 15.7208L5.3912 8.3104L12.284 6.2392Z"
        fill="#876929"
      />
      <path d="M13.5224 14.64L5.0584 15.8304L5.0592 8.1664L13.524 9.3584L13.5224 14.64Z" fill="#D9A741" />
      <path
        d="M9.2336 14.1552L12.284 14.5432L12.2848 9.4528L9.2344 9.8408L9.2336 14.1552ZM6.1608 13.764L8.14 14.016L8.1408 9.98L6.1608 10.232V13.764Z"
        fill="#876929"
      />
      <path d="M5.0592 8.1664L12.2848 6L20.5672 8.4856L13.528 9.3592L5.0592 8.1664Z" fill="#624A1E" />
      <path
        d="M20.5632 13.48L12.284 14.5248L12.2848 9.4528L20.564 10.5072L20.5632 13.48ZM20.5624 15.516L20.384 15.524L12.3088 17.9688L12.2832 18L12.2824 22L20.5616 17.8608L20.5624 15.516Z"
        fill="#D9A741"
      />
      <path d="M5.0584 15.8304L12.284 17.9992L20.5624 15.516L13.5224 14.64L5.0584 15.8304Z" fill="#FAD791" />
    </SvgIcon>
  );
}
