import {IntegrationService} from '../../base/types';

export interface Microsoft365InstallProps {
  service: IntegrationService.MICROSOFT_CALENDAR;
  data: {
    tenant: string;
  };
}

export enum MicrosoftIntegrationPath {
  base = '/microsoft/calendar',
  callback = '/microsoft/calendar/callback',
}
