import React from 'react';

import {IntegrationStatusType} from '../types';
import EnabledStatus from './cardStatuses/EnabledStatus';
//import BrokenStatus from './cardStatuses/BrokenStatus';
import InstallingStatus from './cardStatuses/InstallingStatus';
import NotInstalledStatus from './cardStatuses/NotInstalledStatus';
// import WarningStatus from './cardStatuses/WarningStatus';

interface IntegrationCardStatusProps {
  status: IntegrationStatusType | null;
}

function IntegrationCardStatus({status}: IntegrationCardStatusProps) {
  switch (status) {
    case IntegrationStatusType.ENABLED:
      return <EnabledStatus />;

    case IntegrationStatusType.INSTALLING:
      return <InstallingStatus />;

    case IntegrationStatusType.BROKEN:
      return <EnabledStatus />;
    //@DISABLE_STATUSES return (<BrokenStatus/>);

    case IntegrationStatusType.WARNING:
      return <EnabledStatus />;
    //@DISABLE_STATUSES return (<WarningStatus/>);

    default:
      return <NotInstalledStatus />;
  }
}

export default IntegrationCardStatus;
