import React from 'react';

import {Box, styled} from '@mui/material';

const Table = styled(Box)({
  border: '1px solid #ccc',
  borderRadius: '12px',
});

const Row = styled(Box)({
  borderTop: '1px solid #ccc',
  '&:first-of-type': {
    borderTop: '0',
  },
  display: 'flex',
  alignItems: 'center',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
});

const Label = styled(Box)({
  fontSize: '14px',
  width: '40%',
});

const Value = styled(Box)({
  fontSize: '14px',
  textOverflow: 'hidden',
  width: '60%',
});

interface ListItemTableProps {
  rows: {label: string; value: string}[];
}

export default function ListItemTable(props: ListItemTableProps) {
  const {rows} = props;

  return (
    <Table>
      {rows.map(({label, value}, index) => (
        <Row key={label}>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </Row>
      ))}
    </Table>
  );
}
