import {IntegrationService} from '../../base/types';

export enum SalesforceBackendIntegrationPath {
  base = '/salesforce_backend',
}

export interface SalesforceBackendIntegrationInstallProps {
  service: IntegrationService.SALESFORCE_BACKEND;
  data: any;
}

export enum SalesforceBackendEnvironment {
  live = 'salesforce_backend_live',
  sandbox = 'salesforce_backend_sandbox',
}
