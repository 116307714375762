import React from 'react';

import {Grid} from '@mui/material';

import {statusStyles} from './styles';

export default function NotInstalledStatus() {
  return (
    <Grid
      sx={{
        ...statusStyles,
        color: '#757679',
        backgroundColor: 'rgba(152, 165, 174, 0.06)',
      }}>
      <Grid item>Not installed</Grid>
    </Grid>
  );
}
