import React, {useCallback, useState} from 'react';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import {runNangoAuthFlow} from '../../../base/helpers/runNangoAuthFlow';
import GongIcon from '../../../base/icons/GongIcon';
import {IntegrationService} from '../../../base/types';
import {GONG_DESCRIPTION, GONG_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation, {createIntegration} from '../../hooks/useIntegrationInstallationMutation';

const GONG_PROVIDER = 'gong-oauth';

export default function GongIntegrationScreen() {
  const service = IntegrationService.GONG;

  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, service);

  const [installError, setInstallError] = useState('');

  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({});
  const [winOpened, setWinOpened] = useState(false);

  const onInstallClick = useCallback(async () => {
    if (winOpened) {
      return;
    }

    setWinOpened(true);

    try {
      const {result: nangoParams} = await createIntegration({service, data: {providerConfigKey: GONG_PROVIDER}});
      const data = await runNangoAuthFlow(nangoParams);
      mutate({service, data});
    } catch (err: any) {
      if (err.type === 'windowClosed') {
        console.log(err.message);
      } else {
        setInstallError(err.message);
      }
    }

    setWinOpened(false);
  }, [winOpened, mutate, service]);

  if (isLoading || isInstallationLoading) {
    return <LoadingBox />;
  }

  if (isError || installError) {
    return <ApiErrorBox error={error || installError} />;
  }

  return (
    <IntegrationScreen
      title={GONG_TITLE}
      description={GONG_DESCRIPTION}
      icon={<GongIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={onInstallClick}
      isInstallLoading={winOpened}
    />
  );
}
