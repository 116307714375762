import React, {useCallback, useState} from 'react';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import {runNangoAuthFlow} from '../../../base/helpers/runNangoAuthFlow';
import HubspotBackendIcon from '../../../base/icons/HubspotBackendIcon';
import {IntegrationService} from '../../../base/types';
import {HUBSPOT_BACKEND_DESCRIPTION, HUBSPOT_BACKEND_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation, {createIntegration} from '../../hooks/useIntegrationInstallationMutation';

export default function HubspotBackendIntegrationScreen() {
  const service = IntegrationService.HUBSPOT_BACKEND;

  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, service);

  const [winOpened, setWinOpened] = useState(false);
  const [installError, setInstallError] = useState('');

  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({});

  const onInstallClick = useCallback(() => {
    if (winOpened) {
      return;
    }

    setWinOpened(true);

    createIntegration({service, data: {}})
      .then(({result: params}) => runNangoAuthFlow(params))
      .then((result: any) => {
        mutate({service, data: result});
      })
      .catch((err) => {
        if (err.type === 'windowClosed') {
          console.log(err.message);
        } else {
          setInstallError(err.message);
        }
      })
      .finally(() => {
        setWinOpened(false);
      });
  }, [winOpened, mutate, service]);

  if (isLoading || isInstallationLoading) {
    return <LoadingBox />;
  }

  if (isError || installError) {
    return <ApiErrorBox error={error || installError} />;
  }

  return (
    <IntegrationScreen
      title={HUBSPOT_BACKEND_TITLE}
      description={HUBSPOT_BACKEND_DESCRIPTION}
      icon={<HubspotBackendIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={onInstallClick}
      isInstallLoading={winOpened}
    />
  );
}
