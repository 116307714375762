import React, {useCallback, useEffect, useState} from 'react';

import {Box, Chip, Grid, TextField} from '@mui/material';

import getRulesValidation from '../helpers/getRulesValidation';
import TooltipInfo from './TooltipInfo';

const maxLengthEmailView = 26;
const limitEmails = 100;
const popupWrapperSelectStyle = {
  padding: '12px 0',
  border: '1px solid #999',
  borderRadius: '5px',
  background: '#fff',
};
const popupSelectStyle = {
  width: '100%',
  padding: '12px',
  color: '#0680de',
  fontSize: '15px',
  cursor: 'pointer',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
};

function EmailFormListBuilder(props: any) {
  const {arrayEmails, setValue, fieldName, title, info} = props;
  const [emailText, setEmailText] = useState<string>('');
  const [popupEmailText, setPopupEmailText] = useState<string>('');
  const [, setValuesList] = useState<string[]>();

  const splitEmail = useCallback(
    (email: string) => (email.length > maxLengthEmailView ? `${email.slice(0, maxLengthEmailView)}...` : email),
    [],
  );

  useEffect(() => {
    const listEmails = emailText.split(',').map((item) => item.trim());
    const filteredEmailList = listEmails.filter(
      (email) => getRulesValidation('email', email) && !arrayEmails.current.includes(email),
    );

    if (filteredEmailList.length) {
      const emailList = filteredEmailList.join(',');

      setPopupEmailText(emailList);
    } else {
      setPopupEmailText('');
    }
  }, [arrayEmails, emailText]);

  return (
    <Box>
      <Box>
        {title}
        <TooltipInfo css={{top: '3px', right: '-7px'}} title={info} />
      </Box>

      <Grid item container direction="row" width="100%" sx={{p: 1}}>
        {arrayEmails.current.map((email: string) => (
          <Chip
            title={email}
            key={email}
            sx={{m: '3px'}}
            label={splitEmail(email)}
            variant="outlined"
            onDelete={(e) => {
              arrayEmails.current = arrayEmails.current.filter((item: any) => item !== email);
              setValue(fieldName, arrayEmails.current);
              setValuesList(arrayEmails.current);
            }}
          />
        ))}
      </Grid>
      <Grid sx={{width: '100%', height: '30px'}}>
        <TextField
          sx={{width: '100%'}}
          value={emailText}
          multiline={false}
          size="small"
          label="Enter email"
          onChange={({target: {value}}) => {
            setEmailText(value);
          }}
          autoComplete="off"
        />
        {popupEmailText ? (
          <Box sx={popupWrapperSelectStyle}>
            <Box
              sx={popupSelectStyle}
              onClick={(e) => {
                if (popupEmailText && arrayEmails.current.length < limitEmails) {
                  arrayEmails.current.push(...popupEmailText.split(','));
                }

                setValue(fieldName, arrayEmails.current);
                setEmailText('');
              }}>
              {popupEmailText
                .split(',')
                .map((email) => splitEmail(email))
                .join(', ')}
            </Box>
          </Box>
        ) : null}
      </Grid>
    </Box>
  );
}

export default EmailFormListBuilder;
