import {IntegrationService} from '../../base/types';

export enum SalesforceTelephonyIntegrationPath {
  base = '/salesforce_telephony',
  install = '/salesforce_telephony/install',
}

export interface SalesforceTelephonyIntegrationInstallProps {
  service: IntegrationService.SALESFORCE_TELEPHONY;
  data: any;
}
