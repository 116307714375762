export const IntegrationLogEventType = {
  INSTALLATION_REQUESTED: 'installation_requested',
  UNINSTALLATION_REQUESTED: 'uninstallation_requested',

  ERROR: 'error',
  WARNING: 'warning',

  // MS Teams Presence cron jobs
  AUTH_PROLONGATION_JOB_STARTED: 'auth_prolongation_job_started',
  SUBSCRIPTION_PROLONGATION_JOB_STARTED: 'subscription_prolongation_job_started',

  // MS Teams Presence tasks
  MS_AUTHORIZATION_SAVED: 'ms_authorization_saved',
  PRESENCE_SYNC_REQUESTED: 'presence_sync_requested',
  USERS_SYNC_REQUESTED: 'users_sync_requested',
  USERS_SYNC_NOT_REQUIRED: 'users_sync_not_required',
  USERS_SYNC_FINISHED: 'users_sync_finished',
  WDS_SYNC_REQUESTED: 'wds_sync_requested',
  WDS_SYNC_FINISHED: 'wds_sync_finished',
};
