import {useCallback} from 'react';
import {useQueryClient} from 'react-query';

export default function useConsoleIntegrationInvalidateTrigger() {
  const qc = useQueryClient();

  return useCallback(() => {
    qc.resetQueries('integrations');
  }, [qc]);
}
