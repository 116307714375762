import React, {useCallback, useRef} from 'react';
import {Link, useParams} from 'react-router-dom';

import {Box, Breadcrumbs, ButtonProps, CircularProgress, IconButton, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {BotApiKey} from '@wildix/xbees-users-client';

import CancelButton from '../../../base/components/button/CancelButton';
import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import formatDateString from '../../../base/helpers/formatDateString';
import ChatBotsIcon from '../../../base/icons/ChatBotsIcon';
import CopyIcon24 from '../../../base/icons/CopyIcon24';
import RemoveIcon20 from '../../../base/icons/RemoveIcon20';
import {CHATBOTS_TITLE} from '../../../texts';
import {BotsPath} from '../constants';
import useGetBotQuery from '../hooks/useGetBotQuery';
import useListBotApiKeysQuery from '../hooks/useListBotApiKeysQuery';
import BotEditApiKeysAddDialog from './BotEditApiKeysAddDialog';
import BotEditApiKeysDeleteDialog from './BotEditApiKeysDeleteDialog';
import BotEditApiKeysViewDialog from './BotEditApiKeysViewDialog';

interface BotsAddScreenParams extends Record<string, string> {
  botId: string;
}

function ClickToRevealButton(props: ButtonProps) {
  return (
    <Button
      sx={{
        backgroundColor: '#00000014',
        borderRadius: '16px',
        textTransform: 'none',
        fontSize: '13px',
        fontWeight: '400',
        color: '#757679',
        padding: '3px 4px 3px 8px',
      }}
      {...props}>
      Click to reveal
      <CopyIcon24 />
    </Button>
  );
}

export default function BotEditApiKeysScreen() {
  const params = useParams<BotsAddScreenParams>();

  const queryGetBot = useGetBotQuery(params.botId);
  const queryApiKeys = useListBotApiKeysQuery(params.botId);

  const createDialogRef = useRef<any>();
  const deleteDialogRef = useRef<any>();
  const viewDialogRef = useRef<any>();

  const onDeleteClick = useCallback((key: BotApiKey) => {
    if (deleteDialogRef.current) {
      deleteDialogRef.current.open(key);
    }
  }, []);

  const onClickToRevealClick = (key: BotApiKey) => {
    if (viewDialogRef.current) {
      viewDialogRef.current.open(key.secret);
    }
  };

  const onCreateClick = () => {
    if (createDialogRef.current) {
      createDialogRef.current.open();
    }
  };

  if (queryGetBot.isError || queryApiKeys.isError) {
    return <ApiErrorBox error={queryGetBot.error || queryApiKeys.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={BotsPath.base}>
          {CHATBOTS_TITLE}
        </Typography>
        <Typography color="text.primary" component={Link} to={BotsPath.edit.replace(':botId', params.botId!)}>
          {queryGetBot.data ? queryGetBot.data.bot.name : <CircularProgress size={12} />}
        </Typography>
        <Typography color="text.primary">API Keys</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <ChatBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            {queryGetBot.data ? `${queryGetBot.data.bot.name} API Keys` : <CircularProgress size={12} />}
          </Typography>
          <Typography color="text.primary" mt={4}>
            API keys are essential for authenticating and authorizing access in chat services, providing secure and
            controlled interactions. Using an API key, the Bot can interact with customers by responding to their
            messages and performing various tasks within the chat through the SDK.
          </Typography>

          {queryApiKeys.isLoading && (
            <Box mt={5}>
              <CircularProgress size={24} />
            </Box>
          )}
          {queryApiKeys.data && queryApiKeys.data.keys.length > 0 && (
            <TableContainer sx={{mt: 5, borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="35%">Name</TableCell>
                    <TableCell width="30%">Secret</TableCell>
                    <TableCell width="30%">Created</TableCell>
                    <TableCell width="5%" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {queryApiKeys.data &&
                    queryApiKeys.data.keys.map((row) => (
                      <TableRow key={row.secret} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell>{row.name || '-'}</TableCell>
                        <TableCell>
                          <ClickToRevealButton onClick={() => onClickToRevealClick(row)} />
                        </TableCell>
                        <TableCell>{formatDateString(row.createdAt)}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => onDeleteClick(row)}
                            sx={{width: '32px', height: '32px', padding: '6px'}}>
                            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box mt={4}>
            <CancelButton onClick={onCreateClick} variant="contained" disableElevation size="medium">
              Create new API Key
            </CancelButton>
          </Box>
        </Box>
      </Box>
      {queryGetBot.data && <BotEditApiKeysAddDialog bot={queryGetBot.data.bot} apiRef={createDialogRef} />}
      {queryGetBot.data && <BotEditApiKeysDeleteDialog bot={queryGetBot.data.bot} apiRef={deleteDialogRef} />}
      <BotEditApiKeysViewDialog apiRef={viewDialogRef} />
    </Box>
  );
}
