export default function ChatBotsIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.291 22.7745C25.0535 22.9219 24.7795 23 24.5 23H22.5C22.2205 23 21.9465 22.9219 21.709 22.7745L11 16.1275V28.9219L17.6996 33.5279C18.5136 34.0875 19 35.0121 19 36V39.6618L36 29.314V16.1275L25.291 22.7745ZM39 12.5V31L16 45V36L8 30.5V12.5L23.5 3L39 12.5ZM34.7165 13.3933L23.5 6.51864L12.2835 13.3933L22.9277 20H24.0723L34.7165 13.3933Z"
        fill="rgb(75, 75, 75)"
      />
    </svg>
  );
}
