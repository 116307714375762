import {WIM_API} from '../../env';
import token from '../../token';
import ApiError from '../errors/ApiError';

export default async function fetchLogs(integrationId: string, offset: number = 0) {
  const options = {
    headers: {Authorization: `Bearer ${token}`},
  };

  return fetch(`${WIM_API}/console/integration-logs?integrationId=${integrationId}&offset=${offset}`, options)
    .then((res) => {
      if (!res.ok) {
        throw new ApiError(res);
      } else {
        return res.json();
      }
    })
    .then(({success, result, message}) => {
      if (!success) {
        throw new Error(message);
      }

      return result;
    });
}
