import React from 'react';

import {SvgIcon, SvgIconProps} from '@mui/material';

export default function RemoveIcon20(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M3.625 5.75H5.04167H16.375"
        stroke="#EE4141"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9582 5.74984V15.6665C14.9582 16.0422 14.8089 16.4026 14.5432 16.6682C14.2776 16.9339 13.9172 17.0832 13.5415 17.0832H6.45817C6.08245 17.0832 5.72211 16.9339 5.45644 16.6682C5.19076 16.4026 5.0415 16.0422 5.0415 15.6665V5.74984M7.1665 5.74984V4.33317C7.1665 3.95745 7.31576 3.59711 7.58144 3.33144C7.84711 3.06576 8.20745 2.9165 8.58317 2.9165H11.4165C11.7922 2.9165 12.1526 3.06576 12.4182 3.33144C12.6839 3.59711 12.8332 3.95745 12.8332 4.33317V5.74984"
        stroke="#EE4141"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5835 9.2915V13.5415"
        stroke="#EE4141"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4165 9.2915V13.5415"
        stroke="#EE4141"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
