import {styled} from '@mui/material';
import Button from '@mui/material/Button';

const CancelButton = styled(Button)({
  background: '#98A5AE1F',
  color: '#000000DE',
  '&:hover': {
    background: 'rgba(152, 165, 174, 0.24)',
  },
});

export default CancelButton;
