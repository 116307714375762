import {INTEGRATION_WATCHING_INTERVAL_DURATION} from '../../base/constants/constants';
import checkUpdatingIntegrationsPresent from '../../base/helpers/checkUpdatingIntegrationsPresent';
import fetchIntegrations from '../../base/helpers/fetchIntegrations';
import {Integration, IntegrationSetter} from '../../base/types';

let intervalId: number = 0;
const settersArray: IntegrationSetter[] = [];

export default function useConsoleIntegrationStatusWatching(setInstalledServices: IntegrationSetter): {
  watch: (installedServices: Integration[], force?: boolean) => void;
  unwatch: () => void;
} {
  settersArray.push(setInstalledServices);

  const watch = (installedServices: Integration[], force: boolean = false) => {
    if (force) {
      clearInterval(intervalId);
      intervalId = 0;
    }

    if (!intervalId && (force || checkUpdatingIntegrationsPresent(installedServices))) {
      intervalId = window.setInterval(async () => {
        const {installed = []} = await fetchIntegrations();
        settersArray.forEach((setter) => setter(installed));
        setInstalledServices(installed);

        if (!checkUpdatingIntegrationsPresent(installed)) {
          clearInterval(intervalId);
          intervalId = 0;
        }
      }, INTEGRATION_WATCHING_INTERVAL_DURATION);
    }
  };

  const unwatch = () => clearInterval(intervalId);

  return {watch, unwatch};
}
