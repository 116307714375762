import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';

import {find} from 'lodash';
import pick from 'lodash/pick';
import {useSnackbar} from 'material-ui-snackbar-provider';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import getRandomString from '../../../base/helpers/getRandomString';
import WebHookIcon from '../../../base/icons/WebHookIcon';
import {IntegrationService} from '../../../base/types';
import useConsoleIntegrationDeleteMutation from '../../hooks/useConsoleIntegrationDeleteMutation';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';
import {WebhooksIntegrationPath} from '../constants';
import {TransmissionType, WebhookFormData} from '../types';
import WebHookIntegrationForm from './WebHookIntegrationForm';

export default function WebHookIntegrationEditScreen() {
  const snackbar = useSnackbar();
  const params = useParams();
  const {isLoading, isError, isSuccess, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const [isReady, setReady] = useState(false);

  const navigate = useNavigate();
  const form = useForm<WebhookFormData>();
  const {setError} = form;

  const deleteMutation = useConsoleIntegrationDeleteMutation();
  const addMutation = useIntegrationInstallationMutation({
    onSuccess: (response) => {
      navigate(`${WebhooksIntegrationPath.base}?mutate=true`);
    },
    onError: (error: Error) => {
      snackbar.showMessage(error.toString());
    },
  });
  const onSubmit = useCallback(
    async (data: WebhookFormData) => {
      if (!data.id) {
        return;
      }

      const content = {
        ...pick(data, ['type', 'url', 'key']),
        ...(data.type === TransmissionType.SQS ? {secret: data.secretSqs} : {secret: data.secretWebhook}),
        events: data.events.map((key: string) => ({
          value: key,
        })),
      };

      await deleteMutation.mutateAsync(data.id);
      await addMutation.mutateAsync({service: IntegrationService.WEBHOOK, data: content});
    },
    [deleteMutation, addMutation],
  );

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const integration = find(installed, {integrationId: params.integrationId});

    if (!integration) {
      navigate(WebhooksIntegrationPath.base);

      return;
    }

    const events = integration.data.events as {value: string}[];

    const content: WebhookFormData = {
      id: integration.integrationId,
      type: integration.data.type,
      url: integration.data.url,
      key: integration.data.key,
      secretSqs: integration.data.type === TransmissionType.SQS ? integration.data.secret : '',
      secretWebhook: integration.data.type === TransmissionType.WEBHOOK ? integration.data.secret : getRandomString(),
      events: events.map(({value}) => value),
    };

    form.reset(content);
    setReady(true);
  }, [isSuccess, installed, params, form, navigate]);

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={WebhooksIntegrationPath.base}>
          Webhooks
        </Typography>
        <Typography color="text.primary">Integration #{params.integrationId}</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <WebHookIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5}>
          <Typography color="text.primary" variant="h5">
            Webhook Integration #{params.integrationId}
          </Typography>

          {isLoading || !isReady ? (
            <Box mt={5}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <WebHookIntegrationForm form={form} onSubmit={onSubmit} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
