export default function openPopupWindow(url: string, title: string, popupWidth: number, popupHeight: number) {
  const top = window.outerHeight / 2 + window.screenY - popupHeight / 2;
  const left = window.outerWidth / 2 + window.screenX - popupWidth / 2;

  return window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${popupWidth},
      height=${popupHeight},
      top=${top},
      left=${left}`,
  );
}
