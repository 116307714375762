export enum BotsPath {
  base = '/bots',
  add = '/bots/add',
  edit = '/bots/:botId',
  editApiKeys = '/bots/:botId/keys',
}

export enum BotCallbackType {
  SQS = 'SQS',
  WEBHOOK = 'WEBHOOK',
  DIALOGFLOW_CX = 'DIALOGFLOW_CX',
  OPENAI_ASSISTANT = 'OPENAI_ASSISTANT',
}
