import React, {useState} from 'react';
import {Controller} from 'react-hook-form';

import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';

export default function WebhookFieldSqsSecret(props: any) {
  const {form} = props;
  const {control, formState, getValues, setValue} = form;
  const {errors, isSubmitting} = formState;
  const error = errors?.secret;

  const [showPassword, setShowPassword] = useState(false);
  const onShowPasswordClick = () => setShowPassword(!showPassword);

  return (
    <div>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value, name, ref}}) => (
          <TextField
            inputRef={ref}
            name={name}
            type={showPassword ? 'text' : 'password'}
            size="small"
            required
            fullWidth
            id="outlined-required"
            label="AWS Secret"
            placeholder="AWS Secret"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={onShowPasswordClick}>
                    {showPassword ? (
                      <Visibility sx={{width: 18, height: 18}} />
                    ) : (
                      <VisibilityOff sx={{width: 18, height: 18}} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onBlur={onBlur}
            onChange={onChange}
            disabled={isSubmitting}
            value={value}
            error={!!error}
            helperText={error?.message || error?.type}
          />
        )}
        name="secretSqs"
        rules={{required: true}}
      />
    </div>
  );
}
