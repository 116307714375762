import React from 'react';

import {Grid} from '@mui/material';

import {statusStyles} from './styles';

export default function InstallingStatus() {
  return (
    <Grid
      sx={{
        ...statusStyles,
        color: '#167BC8',
        backgroundColor: 'rgba(236, 244, 251, 1)',
      }}>
      <Grid item>Installing...</Grid>
    </Grid>
  );
}
