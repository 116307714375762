const params = new URLSearchParams(window.location.search);
const jwt = params.get('jwt');
let token: string | null;

if (jwt && jwt.length > 0) {
  token = jwt;
  localStorage.setItem('jwt', jwt);
} else {
  token = localStorage.getItem('jwt');
}

export default token;
