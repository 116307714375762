import {IntegrationLogEventType} from './IntegrationLogEventType';

export const EventTypeToTextMap: Record<string, string> = {
  [IntegrationLogEventType.INSTALLATION_REQUESTED]: 'Installation started',
  [IntegrationLogEventType.UNINSTALLATION_REQUESTED]: 'Uninstallation started',

  [IntegrationLogEventType.AUTH_PROLONGATION_JOB_STARTED]: 'Authorization prolongation job started',
  [IntegrationLogEventType.SUBSCRIPTION_PROLONGATION_JOB_STARTED]: 'MS Teams subscription prolongation job started',

  [IntegrationLogEventType.MS_AUTHORIZATION_SAVED]: 'MS Teams authorization saved into DB',
  [IntegrationLogEventType.PRESENCE_SYNC_REQUESTED]: 'MS Teams presence synchronization task started',
  [IntegrationLogEventType.USERS_SYNC_REQUESTED]: 'MS Teams users synchronization task started',
  [IntegrationLogEventType.USERS_SYNC_FINISHED]: 'MS Teams users synchronization task finished',
  [IntegrationLogEventType.USERS_SYNC_NOT_REQUIRED]: "MS Teams users synchronization isn't required",
  [IntegrationLogEventType.WDS_SYNC_REQUESTED]: 'WDS synchronization task started',
  [IntegrationLogEventType.WDS_SYNC_FINISHED]: 'WDS synchronization task finished',
};
