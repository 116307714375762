import {IntegrationService} from '../../base/types';

export interface MicrosoftPresenceInstallProps {
  service: IntegrationService.MICROSOFT_PRESENCE;
  data: {
    code: string;
  };
}

export enum MicrosoftPresenceIntegrationPath {
  base = '/presence',
  callback = '/presence/callback',
}
