export const GOOGLE_CALENDAR_TITLE = 'Google Calendar';
export const GOOGLE_CALENDAR_DESCRIPTION =
  'Provides customers with the possibility to view available slots in the Google calendar of salespeople and schedule meetings with them from x-bees. The user’s status gets automatically updated in x-bees based on the events in the Google calendar.';
export const GOOGLE_CALENDAR_SHORT_DESCRIPTION = GOOGLE_CALENDAR_DESCRIPTION;

export const MICROSOFT_CALENDAR_TITLE = 'Microsoft 365';
export const MICROSOFT_CALENDAR_DESCRIPTION =
  "Provides customers with the possibility to view available slots in the Microsoft 365 calendar of salespeople and schedule meetings with them from x-bees. The user's status gets automatically updated in x-bees based on the events in the Microsoft 365 calendar.";
export const MICROSOFT_CALENDAR_SHORT_DESCRIPTION = MICROSOFT_CALENDAR_DESCRIPTION;

export const MICROSOFT_PRESENCE_TITLE = 'Microsoft Teams Presence';
export const MICROSOFT_PRESENCE_DESCRIPTION =
  "Enables customers to benefit from a two-way presence status sync between MS Teams and x-bees or Collaboration. This feature ensures that the user's presence status is automatically updated in x-bees or Collaboration in real-time, reflecting changes made in MS Teams and vice versa.";
export const MICROSOFT_PRESENCE_SHORT_DESCRIPTION = MICROSOFT_PRESENCE_DESCRIPTION;

export const HUBSPOT_BACKEND_TITLE = 'HubSpot Auto Logging';
export const HUBSPOT_BACKEND_DESCRIPTION =
  'Enables automatic logging of calls and conferences that take place on the Wildix system between users and HubSpot contacts and supports contact lookup.';
export const HUBSPOT_BACKEND_SHORT_DESCRIPTION = HUBSPOT_BACKEND_DESCRIPTION;

export const SALESFORCE_BACKEND_TITLE = 'Salesforce Auto Logging';
export const SALESFORCE_BACKEND_DESCRIPTION =
  'Enables automatic logging of calls and conferences that take place on the Wildix system between users and Salesforce leads and supports contact lookup.';
export const SALESFORCE_BACKEND_SHORT_DESCRIPTION = SALESFORCE_BACKEND_DESCRIPTION;

export const SALESFORCE_COMMUNITY_TITLE = 'Salesforce Partner Community';
export const SALESFORCE_COMMUNITY_DESCRIPTION =
  'Enables automatic logging of calls and conferences that take place on the Wildix system between users and Salesforce leads. From x-bees, users can create new Salesforce leads, find leads, view all the previous interactions with them, create tasks, log activities, and start a new conversation or schedule a call with them.';
export const SALESFORCE_COMMUNITY_MESSAGE_FOR_INTEGRATION_WHICH_NOT_ADDED =
  'Integration is unavailable for your company.';
export const SALESFORCE_COMMUNITY_SHORT_DESCRIPTION = SALESFORCE_COMMUNITY_DESCRIPTION;

export const IFRAME_INTEGRATION_TITLE = 'x-bees Client Integrations';
export const IFRAME_INTEGRATION_DESCRIPTION = 'Integrate popular CRMs and more inside x-bees.';
export const IFRAME_INTEGRATION_MESSAGE_FOR_INTEGRATION_WHICH_NOT_ADDED =
  'Integration is unavailable for your company.';
export const IFRAME_INTEGRATION_SHORT_DESCRIPTION = IFRAME_INTEGRATION_DESCRIPTION;

export const WEBHOOK_TITLE = 'Webhook';
export const WEBHOOK_DESCRIPTION =
  'Webhooks are a powerful integration tool that enables real-time data transfer between different applications, including third-party software, by sending automated HTTP requests when specific events occur. This seamless communication ensures instant updates and improved efficiency across systems, reducing the need for manual intervention.';
export const WEBHOOK_SHORT_DESCRIPTION =
  'Enables real-time data transfer between apps via automated HTTP requests triggered by specific events, webhooks ensure instant updates and reduce manual work.';

export const SALESFORCE_TELEPHONY_TITLE = 'Salesforce Telephony';
export const SALESFORCE_TELEPHONY_SHORT_DESCRIPTION =
  'Allows users to log in and make calls from Salesforce CRM integration.';
export const SALESFORCE_TELEPHONY_MESSAGE_FOR_INTEGRATION_WHICH_NOT_ADDED =
  'Integration is unavailable for your company.';
export const SALESFORCE_TELEPHONY_DESCRIPTION =
  'Important: After installation, salesforce_telephony app is added to OAuth 2.0 applications in WMS -> Integrations -> Applications. Please do not delete it, otherwise the integration will not work.';

export const GONG_TITLE = 'Gong integration';
export const GONG_DESCRIPTION =
  'Produces AI-powered data analyses of call and conference recordings alongside summaries, transcriptions and useful insights.';
export const GONG_SHORT_DESCRIPTION = GONG_DESCRIPTION;

export const DIALOGFLOW_TITLE = 'Google Dialogflow CX';
export const DIALOGFLOW_DESCRIPTION =
  "Dialogflow, a powerful conversational AI platform by Google, enables the creation of sophisticated chatbots and virtual agents. When integrated with Wildix x-bees, a comprehensive business communication solution, Dialogflow can significantly enhance customer interactions by automating responses to common requests. This integration allows x-bees users to leverage Dialogflow's natural language understanding to interpret customer inquiries accurately and provide instant, contextually appropriate responses. As a result, businesses can streamline customer service processes, reduce response times, and improve overall customer satisfaction while freeing up human agents to handle more complex issues. The seamless connectivity between Wildix x-bees and Dialogflow thus represents a step forward in utilizing AI to optimize business communications and elevate the customer experience.";
export const DIALOGFLOW_SHORT_DESCRIPTION =
  'Google Dialogflow automates customer service by providing instant, accurate responses to inquiries, improving efficiency and satisfaction while allowing human agents to focus on complex tasks.';

export const CHATBOTS_TITLE = 'Chat Bots';
export const CHATBOTS_DESCRIPTION =
  'Chatbots handle customer requests efficiently, integrating with tools like Webhooks, AWS SQS, Dialogflow CX, and OpenAI Assistant for seamless and personalized interactions. Webhooks provide real-time updates, AWS SQS ensures scalable message queuing, and Dialogflow CX and OpenAI enhance natural language processing for better customer service.';
export const CHATBOTS_SHORT_DESCRIPTION =
  'Chatbots are advanced conversational agents designed to manage incoming customer requests, providing efficient and personalized responses.';
