import React, {useEffect} from 'react';

import LoadingBox from '../../../base/components/LoadingBox';
import {MICROSOFT_OAUTH_RESPONSE} from '../constants';

export default function MicrosoftAuthPopup() {
  useEffect(() => {
    if (!window.opener) {
      throw new Error('No window opener');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const tenant = urlParams.get('tenant');
    const error = urlParams.get('error');

    if (error) {
      const errorDescription = urlParams.get('error_description') || '';
      window.opener.postMessage({
        event: MICROSOFT_OAUTH_RESPONSE,
        error: decodeURI(error),
        errorDescription: decodeURI(errorDescription),
      });
    } else if (tenant) {
      window.opener.postMessage({
        event: MICROSOFT_OAUTH_RESPONSE,
        tenant,
      });
    } else {
      window.opener.postMessage({
        event: MICROSOFT_OAUTH_RESPONSE,
        error: 'response_mismatch',
        errorDescription: 'Something went wrong',
      });
    }
  }, []);

  return <LoadingBox />;
}
