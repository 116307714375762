import {useMutation, useQueryClient} from 'react-query';
import {UseMutationOptions} from 'react-query/types/react/types';

import {DeleteBotCommand, DeleteBotOutput} from '@wildix/xbees-users-client';

import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';

export default function useDeleteBotMutation(options: UseMutationOptions<DeleteBotOutput, unknown, string>) {
  const qc = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (botId: string) => getXbsUsersClient().send(new DeleteBotCommand({botId})),
    onSuccess: (...args) => {
      if (options?.onSuccess) {
        options.onSuccess(...args);
      }

      qc.resetQueries('chatbots', {exact: true});
    },
  });
}
