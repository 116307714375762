import React from 'react';

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {Box, Tooltip} from '@mui/material';

export default function TooltipInfo(props: any) {
  const {title, css} = props;

  return (
    <Tooltip title={title}>
      <Box sx={{display: 'inline', position: 'relative', top: '8px', right: '10px', color: '#666', ...css}}>
        <HelpOutlineRoundedIcon sx={{fontSize: 16}} />
      </Box>
    </Tooltip>
  );
}
