import React from 'react';

import {Grid} from '@mui/material';

import {IntegrationLogEventType} from '../../constants/IntegrationLogEventType';
import {formatLogItem} from '../../helpers/formatLog';
import {IntegrationLog} from '../../types';

interface IntegrationLogItemProps {
  integrationLog: IntegrationLog;
}

export default function IntegrationLogItem({integrationLog}: IntegrationLogItemProps) {
  const isError: boolean = integrationLog.eventType === IntegrationLogEventType.ERROR;
  const isWarning: boolean = integrationLog.eventType === IntegrationLogEventType.WARNING;

  let styles;

  if (isError) {
    styles = {color: '#EE4141', fontWeight: '500'};
  } else if (isWarning) {
    styles = {color: '#BB5D07', fontWeight: '500'};
  } else {
    styles = {color: '#292C31', fontWeight: '400'};
  }

  const formattedLog = formatLogItem(integrationLog);

  return (
    <Grid
      sx={{
        ...styles,
        fontFamily: 'Roboto',
        fontSize: '14px',
        wordWrap: 'break-word',
      }}>
      {formattedLog}
    </Grid>
  );
}
