import Nango from '@nangohq/frontend';

export interface NangoInstallParams {
  nangoHost: string;
  nangoPublicKey: string;
  providerId: string;
  connectionId: string;
  hmac: string;
  params: {
    [key: string]: string;
  };
}

export async function runNangoAuthFlow(params: NangoInstallParams) {
  console.log('Run auth flow with params', params);

  return new Promise((resolve, reject) => {
    const nango = new Nango({
      host: params.nangoHost,
      publicKey: params.nangoPublicKey,
    });

    nango
      .auth(params.providerId, params.connectionId, {
        params: params.params,
        hmac: params.hmac,
      })
      .then((result: any) => {
        resolve(result);
      })
      .catch((err: {message: string; type: string}) => {
        reject(err);
      });
  });
}
