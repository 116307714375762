import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, Checkbox, Stack, styled} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {WebhookEvent, WebhookFormData} from '../../types';
import WebhookEventsAccordionItem from './WebhookEventsAccordionItem';

const StyledAccordionSummary = styled(AccordionSummary)({
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
});

const StyledAccordion = styled(Accordion)({
  borderTopWidth: '0',
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  '&:nth-of-type(1)': {
    borderTopWidth: '1px',
  },
  '&.Mui-expanded': {
    borderTopWidth: '1px',
    borderRadius: '8px',
  },
});

interface EventsAccordionProps {
  label: string;
  events: WebhookEvent[];
  form: UseFormReturn<WebhookFormData>;
  deprecated?: boolean;
}

export default function WebhookEventsAccordion(props: EventsAccordionProps) {
  const {label, form, events, deprecated} = props;
  const {formState, watch, setValue} = form;
  const {isSubmitting, errors} = formState;

  const eventsSelected = watch('events');
  const eventsMatched = eventsSelected.filter((eventKey) => events.find((eventS) => eventS.key === eventKey));

  const checked = eventsMatched.length === events.length;
  const indeterminate = !checked && eventsMatched.length > 0;

  const onCheckboxClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (checked || indeterminate) {
      setValue(
        'events',
        eventsSelected.filter((eventKey) => !events.find((eventS) => eventS.key === eventKey)),
      );
    } else {
      setValue(
        'events',
        eventsSelected
          .filter((eventKey) => !events.find((eventS) => eventS.key === eventKey))
          .concat(events.map(({key}) => key)),
      );
    }

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <StyledAccordion elevation={0} variant="outlined">
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack alignItems="center" flexDirection="row">
          <Checkbox
            checked={checked}
            disabled={isSubmitting}
            indeterminate={indeterminate}
            onClickCapture={onCheckboxClick}
          />
          {deprecated ? (
            <Box display="flex" alignItems="center">
              <span>{label}</span>
              <Box ml={1} sx={{borderRadius: '3px', background: '#CCC', fontSize: '11px'}}>
                deprecated
              </Box>
            </Box>
          ) : (
            label
          )}
        </Stack>
      </StyledAccordionSummary>
      <AccordionDetails sx={{pl: 0, pr: 0, pt: 0, pb: 0}}>
        {events.map((event) => (
          <WebhookEventsAccordionItem key={event.key} form={form} event={event} />
        ))}
      </AccordionDetails>
    </StyledAccordion>
  );
}
