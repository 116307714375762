import React from 'react';

import IntegrationInstallationBlendrScreen from '../../../base/components/IntegrationInstallationBlendrScreen';
import SalesforceCommunityIcon from '../../../base/icons/SalesforceCommunityIcon';
import {IntegrationService} from '../../../base/types';
import {SALESFORCE_COMMUNITY_TITLE} from '../../../texts';
import {SalesforceCommunityIntegrationPath} from '../types';

export default function SalesforceCommunityInstallationScreen() {
  return (
    <IntegrationInstallationBlendrScreen
      title={SALESFORCE_COMMUNITY_TITLE}
      icon={<SalesforceCommunityIcon width={96} height={96} />}
      service={IntegrationService.SALESFORCE_COMMUNITY}
      serviceLink={SalesforceCommunityIntegrationPath.base}
    />
  );
}
