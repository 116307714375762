import {IntegrationService} from '../../base/types';

export enum SalesforceCommunityIntegrationPath {
  base = '/salesforce_community',
  install = '/salesforce_community/install',
}

export interface SalesforceCommunityInstallProps {
  service: IntegrationService.SALESFORCE_COMMUNITY;
  data: {};
}
