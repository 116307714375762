import React, {useEffect, useState} from 'react';

import getIntegrationStatus from '../helpers/getIntegrationStatus';
import {Integration, IntegrationStatusType} from '../types';
import EnabledStatus from './cardStatuses/EnabledStatus';
import InstallingStatus from './cardStatuses/InstallingStatus';
import NotInstalledStatus from './cardStatuses/NotInstalledStatus';
// import ScreenBrokenStatus from './screenStatuses/ScreenBrokenStatus';
// import ScreenWarningStatus from './screenStatuses/ScreenWarningStatus';

interface IntegrationStatusProps {
  integration: Integration | null;
  showErrors: boolean;
  showLogs: boolean;
  setShowLogs: (value: boolean) => void;
}

function IntegrationStatus({integration, showLogs, showErrors, setShowLogs}: IntegrationStatusProps) {
  const [status, setStatus] = useState<IntegrationStatusType | null>(getIntegrationStatus(integration));

  useEffect(() => {
    const newStatus = getIntegrationStatus(integration);

    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [integration]);

  switch (status) {
    case IntegrationStatusType.ENABLED:
      return <EnabledStatus />;

    case IntegrationStatusType.INSTALLING:
      return <InstallingStatus />;

    case IntegrationStatusType.BROKEN:
      return <EnabledStatus />;
    //@DISABLE_STATUSES
    // return (<ScreenBrokenStatus
    //   showLogs={showLogs}
    //   showErrors={showErrors}
    //   integration={integration}
    //   setShowLogs={setShowLogs}
    // />);

    case IntegrationStatusType.WARNING:
      return <EnabledStatus />;
    //@DISABLE_STATUSES
    // return (<ScreenWarningStatus
    //   showLogs={showLogs}
    //   showErrors={showErrors}
    //   integration={integration}
    //   setShowLogs={setShowLogs}
    // />);

    default:
      return <NotInstalledStatus />;
  }
}

export default IntegrationStatus;
