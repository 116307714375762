import React from 'react';
import {createRoot} from 'react-dom/client';

import {CssBaseline} from '@mui/material';

import Console from './Console';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <>
      <CssBaseline />
      <Console />
    </>
  </React.StrictMode>,
);
