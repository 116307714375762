import * as React from 'react';
import {ChangeEvent, MutableRefObject, useCallback, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';

import LoadingButton from '@mui/lab/LoadingButton';
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {Bot, CreateBotApiKeyCommand} from '@wildix/xbees-users-client';

import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';

interface IntegrationRemoveDialogApi {
  open: CallableFunction;
}

interface BotEditApiKeysAddDialogProps {
  bot: Bot;
  apiRef: MutableRefObject<IntegrationRemoveDialogApi>;
}

export default function BotEditApiKeysAddDialog(props: BotEditApiKeysAddDialogProps) {
  const {bot, apiRef} = props;

  const [open, setOpen] = React.useState(false);

  const [deleteText, setDeleteText] = useState('');
  const onDeleteFieldChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDeleteText(event.target.value);
  }, []);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      await getXbsUsersClient().send(
        new CreateBotApiKeyCommand({
          botId: bot.id,
          name: deleteText || undefined,
        }),
      );
    },
    onSuccess: () => {
      queryClient.resetQueries('chatbots');
      setOpen(false);
    },
  });

  const onDialogClose = () => {
    setOpen(false);
    setDeleteText('');
  };

  const onDeleteButtonClick = () => {
    mutation.mutate();
  };

  apiRef.current = {
    open: () => setOpen(true),
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onDialogClose}>
      <DialogTitle>Create new API Key</DialogTitle>
      <DialogContent>
        {/*<DialogContentText>*/}
        {/*  <span>Are you sure you want to delete this integration ?</span>*/}
        {/*  &nbsp;*/}
        {/*  <b>You can't undo this action.</b>*/}
        {/*</DialogContentText>*/}
        <Box sx={{pt: 2}}>
          <TextField
            size="small"
            label="Name"
            fullWidth
            placeholder="My Test Key"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onDeleteFieldChange}
            value={deleteText}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDialogClose}>
          Cancel
        </Button>
        <LoadingButton loading={mutation.isLoading} color="primary" onClick={onDeleteButtonClick}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
