import * as React from 'react';
import {MutableRefObject} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {IframeIntegration} from '../types';
import IframeIntegrationItemForm from './IframeIntegrationItemForm';

interface IntegrationAddDialogApi {
  open: CallableFunction;
  close: CallableFunction;
}

export interface IframeIntegrationProps {
  integration: IframeIntegration;
  isInstallationLoading: boolean;
  integrationLength: number;
  sendInstallIntegration: Function;
  warningMessage?: string;
  isCILoading?: boolean;
  apiRef: MutableRefObject<IntegrationAddDialogApi>;
}

export default function IframeIntegrationAddDialog(props: IframeIntegrationProps) {
  const {integration, isInstallationLoading, sendInstallIntegration, warningMessage, apiRef} = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onDialogClose = () => {
    setOpen(false);
  };

  apiRef.current = {
    open: () => setOpen(true),
    close: () => setOpen(false),
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onDialogClose}
      aria-labelledby="integration-remove-dialog-title">
      <DialogTitle id="integration-remove-dialog-title">Add Integration</DialogTitle>
      <DialogContent sx={{overflow: 'visible', width: () => (!fullScreen ? 500 : 'auto')}}>
        <IframeIntegrationItemForm
          isPreparedIntegration={false}
          sendInstallIntegration={sendInstallIntegration}
          isInstallationLoading={isInstallationLoading}
          warningMessage={warningMessage}
          integration={integration}
        />
      </DialogContent>
    </Dialog>
  );
}
