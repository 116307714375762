import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {CircularProgress} from '@mui/material';

import useConsoleBlendrInstallationLink from '../../integrations/hooks/useConsoleBlendrInstallationLink';
import useConsoleIntegrations from '../../integrations/hooks/useConsoleIntegrations';
import findIntegrationByService from '../helpers/findIntegrationByService';
import ApiErrorBox from './error/ApiErrorBox';
import IntegrationInstallationScreen from './IntegrationInstallationScreen';
import LoadingBox from './LoadingBox';

export default function SalesforceInstallationBlendrScreen(props: any) {
  const {service, serviceLink, icon, title} = props;
  const {isLoading, isError, error, data: {installed = []} = {}} = useConsoleIntegrations();
  const integration = findIntegrationByService(installed, service);
  const {link, isLoading: isLinkLoading} = useConsoleBlendrInstallationLink(service);
  const navigate = useNavigate();

  const onMessage = useCallback(
    (event: MessageEvent<any>) => {
      if (event.data.event === 'setup-complete') {
        navigate(serviceLink);
      }

      if (event.data.event === 'setup-failed') {
        console.warn('The setup failed', event);
      }

      if (event.data.event === 'setup-canceled') {
        console.warn('The setup was canceled by the user', event);
      }
    },
    [navigate, serviceLink],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage, false);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    if (integration) {
      navigate('/');
    }
  }, [integration, navigate]);

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ApiErrorBox error={error} />;
  }

  return (
    <IntegrationInstallationScreen title={title} icon={icon} link={serviceLink}>
      {isLinkLoading ? <CircularProgress size={24} sx={{mt: 2}} /> : null}
      {link && !isLinkLoading ? (
        <iframe style={{width: '100%', height: '500px', border: 'none'}} id="sso-iframe" src={link} title={service} />
      ) : null}
    </IntegrationInstallationScreen>
  );
}
