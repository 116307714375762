import moment from 'moment';

import {CodeToFormatterMap} from '../constants/CodeToFormatterMap';
import {EventTypeToTextMap} from '../constants/EventTypeToTextMap';
import {IntegrationLogEventType} from '../constants/IntegrationLogEventType';
import {IntegrationLog} from '../types';

export function formatFullLog(integrationId: string, logs: IntegrationLog[]): string {
  return `IntegrationID: ${integrationId}\n${logs.map((log: IntegrationLog) => formatLogItem(log)).join('\n')}`;
}

export function formatLogItem(log: IntegrationLog): string {
  const isError = log.eventType === IntegrationLogEventType.ERROR;
  const isWarning = log.eventType === IntegrationLogEventType.WARNING;

  let result: string = isError ? '! Error: ' : isWarning ? '! Warning: ' : '';
  result += `[ ${moment(log.createdAt).format('MMM DD, HH:mm:ss')} ]: `;

  if (EventTypeToTextMap[log.eventType]) {
    result += EventTypeToTextMap[log.eventType];
  }

  if (isError || isWarning) {
    const formatter = CodeToFormatterMap[log.errorCode ?? log.warningCode];

    if (typeof formatter !== 'undefined') {
      result += formatter(log);
    } else {
      const codes: string[] = [
        log.errorCode ? `error code - ${log.errorCode}` : '',
        log.warningCode ? `warning code - ${log.warningCode}` : '',
      ].filter((i) => !!i);

      result += `unknown error occurred: ${codes.length ? codes.join(',') : 'no error codes present'}`;
    }
  }

  return result;
}
