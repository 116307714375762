import {IntegrationService} from '../../base/types';

export enum TransmissionType {
  WEBHOOK = 'webhook',
  SQS = 'sqs',
}

export interface WebHookInstallProps {
  service: IntegrationService.WEBHOOK;
  data: {
    type: TransmissionType;
    url: string;
    key: string;
    secret: string;
    events: {value: string}[];
  };
}

export interface WebhookFormData {
  id?: string;
  type: TransmissionType;
  url: string;
  key: string;
  secretWebhook: string;
  secretSqs: string;
  events: string[];
}

export interface WebhookEvent {
  key: string;
  deprecated?: boolean;
  description?: string;
}

export interface WebhookEventGroup {
  key: string;
  label: string;
  deprecated?: boolean;
  description?: string;
  events: WebhookEvent[];
}
