import React from 'react';

import {Box, Typography} from '@mui/material';

function AuthorizationFailureBox() {
  return (
    <Box m={2} sx={{padding: 1, pl: 2, pr: 2, border: '1px solid #ccc', borderRadius: '6px', display: 'inline-block'}}>
      <Typography color="text.primary" variant="caption">
        Unauthorized, your session was expired. Please refresh the page or login again.
      </Typography>
    </Box>
  );
}

export default AuthorizationFailureBox;
