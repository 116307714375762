import {useQuery} from 'react-query';

import {ListBotsCommand} from '@wildix/xbees-users-client';

import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';

export default function useListBotsQuery() {
  return useQuery('chatbots', () => getXbsUsersClient().send(new ListBotsCommand({})), {
    retry: false,
  });
}
