import React from 'react';

import {SvgIcon, SvgIconProps} from '@mui/material';

export default function DialogflowIcon24(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_123_19587)">
        <path
          d="M20.9377 5.87207L12.4879 10.7821L4 5.87207V15.6541L8.22491 18.0901V23.0001L20.9377 15.6541V5.87207Z"
          fill="#ED6D23"
        />
        <path d="M12.4879 10.782L4 5.87197L12.4879 1L20.9377 5.87197L12.4879 10.782Z" fill="#F8981D" />
        <path
          d="M12.3356 10.6303L4.03806 5.87249H4L12.4879 10.7825L20.9377 5.91055L20.7474 5.7583L12.3356 10.6303Z"
          fill="#F8981D"
        />
        <path d="M12.4879 10.8197V10.7436L4.03806 5.90967L4 5.94773L12.4879 10.8197Z" fill="#F8981D" />
      </g>
      <defs>
        <clipPath id="clip0_123_19587">
          <rect width="17" height="22" fill="white" transform="translate(4 1)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
