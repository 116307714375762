import {ErrorFormatter, IntegrationLog} from '../types';
import {ErrorCode} from './ErrorCode';
import {WarningCode} from './WarningCode';

export const CodeToFormatterMap: Record<string, ErrorFormatter> = {
  [ErrorCode.WRONG_STATUS]: (log: Partial<IntegrationLog>) => `integration has an unexpected status: ${log.status}`,

  [ErrorCode.SUBSCRIPTIONS_ALREADY_PRESENT]: (log: Partial<IntegrationLog>) =>
    `Provided Microsoft Account already have subscriptions${log.pbxDomains?.length ? ` on next PBXes: ${Array.isArray(log.pbxDomains) ? log.pbxDomains.join(', ') : JSON.stringify(log.pbxDomains)}` : ''}`,
  [ErrorCode.NO_REFRESH_TOKEN]: () => "Can't find Microsoft refresh token for authorization prolongation",
  [ErrorCode.NO_PBX_USERS]: () => "PBX didn't return users' data",
  [ErrorCode.NO_MS_USERS]: () => "Microsoft Teams didn't return users' data",
  [ErrorCode.NO_DYNAMO_USERS]: () => "Can't find users for subscription prolongation for the current integration",
  [ErrorCode.NO_USERS_ON_MS_AND_PBX]: () => "Can't find users with same email both on Microsoft Teams and PBX",
  [ErrorCode.MAX_USERS_LIMIT_REACHED]: () => 'Too many users for subscribe: limit is 300',
  [ErrorCode.UNEXPECTED_ERROR]: (log: Partial<IntegrationLog>) =>
    `Unexpected error occurred, error: ${JSON.stringify(log.error)}`,
  [ErrorCode.USER_SUBSCRIPTION_ERROR]: (log: Partial<IntegrationLog>) =>
    `Error occurred while subscribing to Microsoft Teams users${log.error ? `, error: ${JSON.stringify(log.error)}` : ''}`,
  [ErrorCode.PRESENCE_SUBSCRIPTION_ERROR]: (log: Partial<IntegrationLog>) =>
    `Error occurred while subscribing to Microsoft Teams presences${log.error ? `, error: ${JSON.stringify(log.error)}` : ''}`,
  [ErrorCode.NO_ACTIVE_AUTHORIZATIONS]: () => "Can't find an active Microsoft Authorization for current integration",
  [ErrorCode.MISSING_EXTERNAL_ID]: () => 'Integration was configured with errors: it misses external ID',
  [ErrorCode.MISSING_TENANT_ID]: () => 'Integration was configured with errors: it misses Microsoft tenant ID',

  [WarningCode.PROLONG_USER_SUBSCRIPTION_FAILED]: (log: Partial<IntegrationLog>) =>
    `Failed to prolong subscription to Microsoft Teams user, error: ${JSON.stringify(log.error)}`,
  [WarningCode.PROLONG_PRESENCE_SUBSCRIPTION_FAILED]: (log: Partial<IntegrationLog>) =>
    `Failed to prolong subscription to Microsoft Teams presence, error: ${JSON.stringify(log.error)}`,
  [WarningCode.SYNC_PRESENCE_FAILED]: (log: Partial<IntegrationLog>) =>
    `Error occurred while trying to synchronize Microsoft Teams presence, error: ${JSON.stringify(log.error)}`,
  [WarningCode.SYNC_USERS_FAILED]: (log: Partial<IntegrationLog>) =>
    `Error occurred while trying to synchronize Microsoft Teams users, error: ${JSON.stringify(log.error)}`,
  [WarningCode.WDA_PRESENCE_FAILED]: (log: Partial<IntegrationLog>) =>
    `Error occurred while trying to synchronize presence, error: ${JSON.stringify(log.error)}`,

  [WarningCode.UNKNOWN_SUBSCRIPTION_TYPE]: (log: Partial<IntegrationLog>) =>
    `Got an unknown subscription type for prolongation: ${log.subscriptionSubType}`,
  [WarningCode.SUBSCRIPTIONS_NOT_FOUND]: () =>
    "Didn't find existent Microsoft Teams presence subscriptions while trying to synchronize users",

  [WarningCode.NO_PBX_USERS]: () => "PBX didn't return users' data while trying to synchronize presences",
  [WarningCode.BAD_TIMESTAMP]: (log: Partial<IntegrationLog>) =>
    `Wrong timestamp was passed to presence synchronization procedure, timestamp: ${log.until}`,

  [WarningCode.MISSING_EMAIL]: (log: Partial<IntegrationLog>) =>
    `Tried to synchronize presence of user that doesn't have an email, resourceId: ${log.resourceId}`,
};
