import React, {ReactElement} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Button, Card, CardContent, Grid, Typography} from '@mui/material';

import {IframeData, IframeIntegrationIdTemplate} from '../types';

import styled from '@emotion/styled';

export interface IframeIntegrationsProps {
  apps: string[];
  integrationId: string;
  source: IframeData;
  icon: ReactElement;
  currentTemplateId: IframeIntegrationIdTemplate;
  isLoading: boolean;
  isMaxInstalledIntegrations: boolean;
  handleIntegration: (templateId: IframeIntegrationIdTemplate, integrationId: string) => null;
}

const ApplicationChip = styled('div')({
  background: '#164C7414',
  fontSize: '12px',
  borderRadius: '6px',
  padding: '4px 8px',
  display: 'inline',
});

function IframeIntegrationPreparedItem(props: IframeIntegrationsProps) {
  const {
    apps,
    source,
    icon,
    isLoading,
    integrationId,
    currentTemplateId,
    isMaxInstalledIntegrations,
    handleIntegration,
  } = props;
  const {title, description, templateId} = source;
  const isCurrentIntegrationInProcess = currentTemplateId === templateId;

  return (
    <Card sx={{minWidth: 268, maxWidth: 268, m: '14px', pb: 2}}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 0,
          textAlign: 'left',
          justifyContent: 'space-between',
          height: '100%',
        }}>
        <CardContent>
          {icon}
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Grid container spacing={0.5} sx={{paddingTop: 0.5, paddingBottom: 0.5}}>
            {apps.map((name: string) => (
              <Grid item key={name}>
                <ApplicationChip>{name}</ApplicationChip>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body2" mt={1}>
            {description}
          </Typography>
        </CardContent>
        <Grid sx={{display: 'flex'}}>
          {!integrationId ? (
            <Box>
              <LoadingButton
                sx={{width: 'min-content', ml: '14px', mr: '14px'}}
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  handleIntegration(templateId as IframeIntegrationIdTemplate, integrationId);
                }}
                loading={Boolean(isLoading && isCurrentIntegrationInProcess)}
                disabled={isMaxInstalledIntegrations || (isLoading && !isCurrentIntegrationInProcess)}>
                Install
              </LoadingButton>
            </Box>
          ) : (
            <Grid container>
              <Grid item ml={2}>
                <Button variant="contained" startIcon={<CheckIcon />} disabled size="small">
                  Installed
                </Button>
              </Grid>
              <Grid item ml={1}>
                <LoadingButton
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                  size="small"
                  onClick={() => {
                    handleIntegration(templateId as IframeIntegrationIdTemplate, integrationId);
                  }}
                  loading={Boolean(isLoading ? isCurrentIntegrationInProcess : null)}>
                  Delete
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default IframeIntegrationPreparedItem;
