export default function MicrosoftCalendarIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.4286 1.14286H1.14288V11.4286H11.4286V1.14286Z" fill="#F25022" />
      <path d="M11.4286 12.5714H1.14288V22.8571H11.4286V12.5714Z" fill="#00A4EF" />
      <path d="M22.8571 1.14286H12.5714V11.4286H22.8571V1.14286Z" fill="#7FBA00" />
      <path d="M22.8571 12.5714H12.5714V22.8571H22.8571V12.5714Z" fill="#FFB900" />
    </svg>
  );
}
