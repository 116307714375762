import React from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

import CancelButton from '../../../base/components/button/CancelButton';
import getRandomString from '../../../base/helpers/getRandomString';
import DialogflowIcon24 from '../../../base/icons/DialogflowIcon24';
import OpenaiIcon24 from '../../../base/icons/OpenaiIcon24';
import SqsIcon24 from '../../../base/icons/SqsIcon24';
import WebhookIcon24 from '../../../base/icons/WebhookIcon24';
import {BotCallbackType, BotsPath} from '../constants';
import {BotFormData} from '../types';
import BotDialogflowCxAgentField from './fields/BotDialogflowCxAgentField';
import BotDialogflowCxCredentialsField from './fields/BotDialogflowCxCredentialsField';
import BotDialogflowCxLanguageField from './fields/BotDialogflowCxLanguageField';
import BotDialogflowCxLocationField from './fields/BotDialogflowCxLocationField';
import BotNameField from './fields/BotNameField';
import BotOpenAiAssistantField from './fields/BotOpenAiAssistantField';
import BotOpenAiKeyField from './fields/BotOpenAiKeyField';
import BotPictureField from './fields/BotPictureField';
import BotSearchableField from './fields/BotSearchableField';
import BotSqsKeyField from './fields/BotSqsKeyField';
import BotSqsSecretField from './fields/BotSqsSecretField';
import BotSqsUrlField from './fields/BotSqsUrlField';
import BotWebhookSecretField from './fields/BotWebhookSecretField';
import BotWebhookUrlField from './fields/BotWebhookUrlField';

interface BotFormProps {
  form: UseFormReturn<BotFormData>;
  onSubmit: (data: BotFormData) => void;
}

export default function BotForm(props: BotFormProps) {
  const navigate = useNavigate();
  const {form, onSubmit} = props;
  const {handleSubmit, formState, watch, setValue} = form;
  const {isSubmitting, errors} = formState;

  const id = watch('id');
  const callback = watch('callback');
  const type = callback?.endpoint?.sqs
    ? BotCallbackType.SQS
    : callback?.endpoint?.webhook
      ? BotCallbackType.WEBHOOK
      : callback?.endpoint?.dialogflowCx
        ? BotCallbackType.DIALOGFLOW_CX
        : callback?.endpoint?.openAiAssistant
          ? BotCallbackType.OPENAI_ASSISTANT
          : BotCallbackType.WEBHOOK;

  const onTypeChange = (e: React.MouseEvent<HTMLElement>, value: unknown) => {
    if (!value) {
      return;
    }

    if (value === BotCallbackType.WEBHOOK) {
      setValue('callback', {
        endpoint: {
          webhook: {
            url: '',
            secret: getRandomString(),
          },
        },
      });
    } else if (value === BotCallbackType.SQS) {
      setValue('callback', {
        endpoint: {
          sqs: {
            url: '',
            key: '',
            secret: '',
          },
        },
      });
    } else if (value === BotCallbackType.DIALOGFLOW_CX) {
      setValue('callback', {
        endpoint: {
          dialogflowCx: {
            credentials: null,
            agent: '',
            location: '',
            language: '',
          },
        },
      });
    } else if (value === BotCallbackType.OPENAI_ASSISTANT) {
      setValue('callback', {
        endpoint: {
          openAiAssistant: {
            key: '',
            assistantId: '',
          },
        },
      });
    } else {
      throw new Error(`Illegal ${value} callback type.`);
    }
  };

  const onCancelClick = () => {
    navigate(BotsPath.base);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={2}>
        <Typography sx={{fontSize: '16px', fontWeight: '500', mt: 4}}>Select name and picture.</Typography>
        <Box mt={2} width="75%">
          <BotNameField form={form} />
        </Box>
        <Typography sx={{fontSize: '14px', fontWeight: '600', color: 'rgba(0, 0, 0, 0.54)', mt: 2}}>Picture</Typography>
        <Box mt={1}>
          <BotPictureField form={form} />
        </Box>
        <Typography sx={{fontSize: '16px', fontWeight: '500', mt: 4}}>
          Select the integration type to handle chat events.
        </Typography>
        <Box mt={1}>
          <ToggleButtonGroup disabled={isSubmitting} size="medium" exclusive value={type} onChange={onTypeChange}>
            <ToggleButton value={BotCallbackType.WEBHOOK}>
              <WebhookIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                Webhook
              </Box>
            </ToggleButton>
            <ToggleButton value={BotCallbackType.SQS}>
              <SqsIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                AWS SQS
              </Box>
            </ToggleButton>
            <ToggleButton value={BotCallbackType.DIALOGFLOW_CX}>
              <DialogflowIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                Dialogflow CX
              </Box>
            </ToggleButton>
            <ToggleButton value={BotCallbackType.OPENAI_ASSISTANT}>
              <OpenaiIcon24 />
              <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
                OpenAI assistant
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box mt={2}>
          {type === BotCallbackType.WEBHOOK && (
            <>
              <Box mt={4} width="75%">
                <BotWebhookUrlField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * Specified URL will be used by the integration to send POST requests with the event payload.
                </Typography>
              </Box>
              <Box mt={3} width="75%">
                <BotWebhookSecretField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * A secret verifies incoming HTTP requests are from the Wildix system.
                </Typography>
              </Box>
              <Alert severity="info" sx={{mt: 3}}>
                The endpoint should be publicly accessible with a valid HTTPS certificate, accept HTTP POST requests
                with JSON payloads, respond with codes from 200 to 299 as quickly as possible, and handle multiple
                identical calls in case of retries due to network or server failures (up to 3 times).
              </Alert>
            </>
          )}
          {type === BotCallbackType.SQS && (
            <>
              <Box mt={4} width="75%">
                <BotSqsUrlField form={form} />
              </Box>
              <Box mt={3} width="75%">
                <BotSqsKeyField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * Specify access key id that will be used to sign request to the SQS.
                </Typography>
              </Box>
              <Box mt={3} width="75%">
                <BotSqsSecretField form={form} />
                <Typography sx={{fontSize: '12px', color: '#757679', p: 1, pb: 0}}>
                  * Specify secret access key that will be used to sign request to the SQS.
                </Typography>
              </Box>
            </>
          )}
          {type === BotCallbackType.DIALOGFLOW_CX && (
            <Box mt={4} width="75%">
              <BotDialogflowCxCredentialsField form={form} />
              <Box mt={3} sx={{display: 'flex', flexDirection: 'row'}}>
                <Box sx={{flex: '0.5', pr: 3}}>
                  <BotDialogflowCxLocationField form={form} />
                </Box>
                <Box sx={{flex: '0.5', pl: 3}}>
                  <BotDialogflowCxLanguageField form={form} />
                </Box>
              </Box>
              <Box mt={3}>
                <BotDialogflowCxAgentField form={form} />
              </Box>
            </Box>
          )}
          {type === BotCallbackType.OPENAI_ASSISTANT && (
            <Box mt={4} width="75%">
              <BotOpenAiKeyField form={form} />
              <Box mt={3}>
                <BotOpenAiAssistantField form={form} />
              </Box>
            </Box>
          )}
        </Box>

        <Typography sx={{fontSize: '16px', fontWeight: '500', mt: 4}}>Select interaction options.</Typography>
        <Box mt={1}>
          <BotSearchableField form={form} />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={6}>
        <CancelButton
          sx={{width: '96px'}}
          disabled={isSubmitting}
          onClick={onCancelClick}
          variant="contained"
          disableElevation
          size="medium">
          Cancel
        </CancelButton>
        <LoadingButton
          loading={isSubmitting}
          sx={{ml: 2, width: '96px'}}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          size="medium">
          {id ? 'Save' : 'Add'}
        </LoadingButton>
      </Box>
    </form>
  );
}
