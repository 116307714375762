import React, {MouseEventHandler} from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import {Grid} from '@mui/material';

interface DeleteBtnProps {
  onDeleteClick: MouseEventHandler;
  isDeleteLoading: boolean;
}

function DeleteBtn({onDeleteClick, isDeleteLoading}: DeleteBtnProps) {
  return (
    <Grid item ml={1}>
      <LoadingButton
        variant="outlined"
        color="error"
        startIcon={<DeleteOutlineIcon />}
        size="medium"
        onClick={onDeleteClick}
        loading={isDeleteLoading}>
        Delete
      </LoadingButton>
    </Grid>
  );
}

export default DeleteBtn;
