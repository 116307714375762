import React from 'react';
import {Controller} from 'react-hook-form';

import {TextField} from '@mui/material';

export default function WebhookFieldSqsKey(props: any) {
  const {form} = props;
  const {control, formState} = form;
  const {errors, isSubmitting} = formState;
  const error = errors?.key;

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          id="outlined-required"
          label="AWS Key"
          placeholder="AWS Key"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={onBlur}
          onChange={onChange}
          disabled={isSubmitting}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
      name="key"
      rules={{required: true}}
    />
  );
}
