import React, {useCallback} from 'react';
import {Controller, FieldErrors, UseFormReturn} from 'react-hook-form';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import {IconButton, InputAdornment, TextField} from '@mui/material';

import {BotEndpoint} from '@wildix/xbees-users-client';

import copy from 'copy-to-clipboard';
import {useSnackbar} from 'material-ui-snackbar-provider';

import getRandomString from '../../../../base/helpers/getRandomString';
import {BotFormData} from '../../types';

interface BotWebhookSecretFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotWebhookSecretField(props: BotWebhookSecretFieldProps) {
  const snackbar = useSnackbar();
  const {form} = props;
  const {control, formState, getValues, setValue} = form;
  const {errors, isSubmitting} = formState;
  const error = (errors?.callback?.endpoint as FieldErrors<BotEndpoint.WebhookMember>)?.webhook?.secret;

  const onSecretCopyClick = useCallback(() => {
    copy(getValues('callback.endpoint.webhook.secret'));
    snackbar.showMessage('Copied.');
  }, [getValues]);

  const onRefreshSecretClick = useCallback(() => {
    setValue('callback.endpoint.webhook.secret', getRandomString());
  }, [setValue]);

  return (
    <div>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value, name, ref}}) => (
          <TextField
            inputRef={ref}
            name={name}
            size="small"
            required
            fullWidth
            id="outlined-required"
            label="Secret"
            placeholder="Secret"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={onRefreshSecretClick}>
                    <RefreshIcon sx={{width: 18, height: 18}} />
                  </IconButton>
                  <IconButton edge="end" size="small" sx={{ml: 1}} onClick={onSecretCopyClick}>
                    <ContentCopyIcon sx={{width: 16, height: 16}} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onBlur={onBlur}
            onChange={onChange}
            disabled={isSubmitting}
            value={value}
            error={!!error}
            helperText={error?.message || error?.type}
          />
        )}
        name="callback.endpoint.webhook.secret"
        rules={{required: true}}
      />
    </div>
  );
}
// name=""
