import * as React from 'react';
import {ChangeEvent, MutableRefObject, useCallback, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {Bot, BotApiKey, DeleteBotApiKeyCommand} from '@wildix/xbees-users-client';

import getXbsUsersClient from '../../../base/helpers/getXbsUsersClient';

interface IntegrationRemoveDialogApi {
  open: CallableFunction;
}

interface IntegrationRemoveDialogProps {
  apiRef: MutableRefObject<IntegrationRemoveDialogApi>;
  bot: Bot;
}

export default function BotEditApiKeysDeleteDialog(props: IntegrationRemoveDialogProps) {
  const {apiRef, bot} = props;

  const [open, setOpen] = React.useState(false);
  const [apiKey, setApiKey] = React.useState<BotApiKey | undefined>();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      if (!apiKey) {
        throw new Error('Illegal apiKey state.');
      }

      await getXbsUsersClient().send(
        new DeleteBotApiKeyCommand({
          botId: bot.id,
          apiKeySecret: apiKey.secret,
        }),
      );
    },
    onSuccess: () => {
      queryClient.resetQueries('chatbots');
      setOpen(false);
    },
  });

  const onDialogClose = () => {
    setOpen(false);
    setDeleteText('');
  };

  const onDeleteButtonClick = () => {
    mutation.mutate();
  };

  apiRef.current = {
    open: (apiKey: BotApiKey) => {
      setApiKey(apiKey);
      setOpen(true);
    },
  };

  const [deleteText, setDeleteText] = useState('');
  const onDeleteFieldChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDeleteText(event.target.value);
  }, []);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onDialogClose}>
      <DialogTitle>{bot.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>Are you sure you want to delete this API Key ?</span>
          &nbsp;
          <b>You can't undo this action.</b>
        </DialogContentText>
        <DialogContentText sx={{pt: 1}}>
          To confirm deletion, enter the phase <b>delete</b>.
        </DialogContentText>
        <Box sx={{pt: 2}}>
          <TextField
            size="small"
            required
            fullWidth
            placeholder="delete"
            autoFocus
            onChange={onDeleteFieldChange}
            value={deleteText}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDialogClose}>
          Cancel
        </Button>
        <LoadingButton
          onClick={onDeleteButtonClick}
          color="error"
          loading={mutation.isLoading}
          startIcon={<DeleteOutlineIcon />}
          disabled={deleteText !== 'delete'}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
