import React, {useEffect, useState} from 'react';

import findIntegrationByService from '../helpers/findIntegrationByService';
import getIntegrationStatus from '../helpers/getIntegrationStatus';
import {Integration, IntegrationStatusType} from '../types';
import IntegrationCardLayout from './IntegrationCardLayout';

interface IntegrationCardProps {
  icon: React.ReactNode;
  href: string;
  title: string;
  description: string;
  integrations: Integration[];
  service: string;
  apps: string[];
}

function IntegrationCard({icon, href, title, description, integrations, service, apps}: IntegrationCardProps) {
  const integration = findIntegrationByService(integrations, service);
  const [status, setStatus] = useState<IntegrationStatusType | null>(getIntegrationStatus(integration));

  useEffect(() => {
    const newStatus = getIntegrationStatus(integration);

    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [integration]);

  return (
    <IntegrationCardLayout
      status={status}
      icon={icon}
      href={href}
      title={title}
      description={description}
      apps={apps}
    />
  );
}

export default IntegrationCard;
